<template>
  <div>
    <div v-if="
      user.id_role === 'ptsp_provinsi' ||
      this.user.id_role === 'ptsp_prov' ||
      user.id_role === 'ptsp_kabupaten' ||
      user.id_role === 'ptsp_kota'
    ">
      <div v-if="surveyor == null"></div>
      <div v-else>
        <div class="row mr-1" v-if="
          Date.now() >= new Date(surveyor.tanggal_mulai) &&
          Date.now() <= new Date(surveyor.tanggal_selesai)
        ">
          <div class="col-12">
            <b-alert show variant="primary">Verifikasi dan validasi lapangan akan dilakukan pada tanggal
              <strong>{{ format_date(surveyor.tanggal_mulai) }}</strong> sampai
              dengan
              <strong> {{ format_date(surveyor.tanggal_selesai) }}</strong> oleh
              surveyor dari PT Surveyor Indonesia dengan nama
              <strong> {{ surveyor.name_user }}</strong>
            </b-alert>
          </div>
        </div>
      </div>

      <b-row class="mr-1">
        <b-col md="8"></b-col>
        <b-col md="4">
          <b-form inline class="mb-5" style="float: right">
            <label class="mr-sm-2 font-size-h6" for="inline-form-custom-select-pref">Tahun
            </label>
            <b-form-select id="inline-form-custom-select-pref" class="mb-sm-0 font-size-h6" :options="[
              { text: 'Pilih tahun', value: null },
              '2021',
              '2022',
              '2023',
              '2024',
            ]" :value="null" v-model="tahunDashboard" @change="this.loadChartDashboard"></b-form-select>
          </b-form>
        </b-col>
      </b-row>

      <!-- Dashboard 2023 -->
      <div class="row mr-1" v-if="tahunDashboard == '2023' || tahunDashboard == '2024'">
        <div class="col-lg-5">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body text-center mt-5">
              <h3>
                Nilai {{ nilaiPeserta.daerah }} Tahun {{ tahunDashboard }}
              </h3>
              <div v-if="nilaiPeserta.total_akhir >= 80">
                <h1 style="color: green" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button v-if="nilaiPeserta.total_akhir >= 80" class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': 'green' + ' !important',
                    color: 'green' + ' !important',
                  }">
                    SANGAT BAIK
                  </button>
                </div>
              </div>

              <div v-else-if="
                nilaiPeserta.total_akhir >= 60 &&
                nilaiPeserta.total_akhir < 80
              ">
                <h1 style="color: #0f98b4" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': '#0F98B4' + ' !important',
                    color: '#0F98B4' + ' !important',
                  }">
                    BAIK
                  </button>
                </div>
              </div>

              <div v-else>
                <h1 style="color: orange" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': 'orange' + ' !important',
                    color: 'orange' + ' !important',
                  }">
                    KURANG BAIK
                  </button>
                </div>
              </div>
              <div class="mt-7">
                <span style="font-size: 12px; color: red">
                  * Semua nilai sesuai dengan hasil tahapan Verifikasi Lapangan
                  dan Quality Control
                </span>
              </div>
              <b-button class="mt-5" @click="downloadSertifikat()" variant="success"> <i class="fa fa-file-pdf"
                  aria-hidden="true" /> Unduh Sertifikat</b-button>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card card-custom gutter-b bg-primary" style="height: 90%">
            <div class="card-body text-center text-white">
              <h4 class="mt-5">
                Daftar Hasil Akhir Pemaparan Nomine dan Uji Petik
              </h4>
              <div class="mt-10">
                <b-button data-toggle="modal" data-target="#modal-daftarNomine" @click="initDatatable()"
                  variant="success" style="width: 100px; font-size: 14px"><i class="fa fa-eye"></i> Lihat</b-button>
              </div>

              <div class="mt-10"><b-button v-if="checkUrutan()" class="mt-10" @click="downloadSertifikatPeringkat()" variant="secondary"> <i class="fa fa-file-pdf"
                aria-hidden="true" /> Unduh Sertifikat</b-button></div>

              <!-- <h1 v-if="user.id_role == 'ptsp_provinsi'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/38
              </h1>
              <h3 v-if="user.id_role == 'ptsp_kabupaten'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/415
              </h3>
              <h3 v-if="user.id_role == 'ptsp_kota'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/93
              </h3>
              <h3 v-if="user.id_role == 'ppb_kl'">
                {{ nilaiPeserta.peringkat_final }}/18
              </h3>
              <img
              class="mt-2"
                width="80"
                height="80"
                src="https://img.icons8.com/external-fauzidea-flat-fauzidea/64/external-trophy-back-to-school-fauzidea-flat-fauzidea.png"
                alt="external-trophy-back-to-school-fauzidea-flat-fauzidea"
              /> -->
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card card-custom gutter-b bg-success text-white" style="cursor: pointer" data-toggle="modal"
            data-target="#modal-compareNilai">
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span> Nilai {{ user.name }} Berdasarkan Tahun Penilaian </span>
              </div>
              <div class="col-md-2" style="">
                <i style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  " class="fa fa-arrow-up text-success"></i>
              </div>
            </div>
          </div>
          <div class="card card-custom gutter-b bg-success text-white" style="cursor: pointer" data-toggle="modal"
            data-target="#modal-daftarPeserta" @click="initDatatablePeserta()">
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span v-if="user.id_role == 'ptsp_provinsi'">
                  Daftar Nilai Seluruh Provinsi
                </span>
                <span v-else-if="user.id_role == 'ptsp_kabupaten'">
                  Daftar Nilai Seluruh Kabupaten
                </span>
                <span v-else-if="user.id_role == 'ptsp_kota'">
                  Daftar Nilai Seluruh Kota
                </span>
                <span v-else-if="user.id_role == 'ppb_kl'">
                  Daftar Nilai Seluruh Kementerian/Lembaga
                </span>
              </div>
              <div class="col-md-2">
                <i style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  " class="fa fa-arrow-up text-success"></i>
              </div>
            </div>
          </div>
          <!-- <div
            class="card card-custom gutter-b bg-success text-white"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#modal-daftarNomine"
            @click="initDatatable()"
          >
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span v-if="user.id_role == 'ptsp_provinsi'"> Daftar Nomine Provinsi </span>
                <span v-else-if="user.id_role == 'ptsp_kabupaten'"> Daftar Nomine Kabupaten </span>
                <span v-else-if="user.id_role == 'ptsp_kota'"> Daftar Nomine Kota </span>
                <span v-else-if="user.id_role == 'ppb_kl'"> Daftar Nomine Kementerian/Lembaga </span>
              </div>
              <div class="col-md-2">
                <i
                  style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  "
                  class="fa fa-arrow-up text-success"
                ></i>
              </div>
            </div>
          </div> -->
        </div>
      </div>

      <!-- Download Sertifikat -->
      <!-- <div>
        <b-card bg-variant="primary" text-variant="white" title="Sertifikat" class="mr-3 mb-5">
          <b-card-text>
            Silahkan unduh sertfikat Penilaian Kinerja PTSP Pemerintah Daerah dan Kementerian/Lembaga tahun {{ tahunDashboard }}
          </b-card-text>
          <b-button @click="downloadSertifikat" variant="dark">Unduh Sertifikat</b-button>
        </b-card>
       </div> -->

      <div class="row" v-if="pm.length != 0">
        <div class="col-lg-5">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <center>
                <h2>NILAI TOTAL</h2>
              </center>
              <center>
                <h2>PENILAIAN MANDIRI PTSP {{ tahunDashboard }}</h2>
              </center>
              <div class="row">
                <div class="col-6" id="chart">
                  <apexchart height="250" :options="optionsTotal" :series="total"></apexchart>
                </div>
                <div class="col-6">
                  <center v-if="totalchart >= 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      -ms-transform: translateY(-50%);
                      transform: translateY(-50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color': optionsTotal.colors[0] + ' !important',
                      color: optionsTotal.colors[0] + ' !important',
                    }">
                      SANGAT BAIK
                    </button>
                  </center>
                  <center v-else-if="totalchart >= 60 && totalchart < 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color': optionsTotal.colors[0] + ' !important',
                      color: optionsTotal.colors[0] + ' !important',
                    }">
                      BAIK
                    </button>
                  </center>
                  <center v-else style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color': optionsTotal.colors[0] + ' !important',
                      color: optionsTotal.colors[0] + ' !important',
                    }">
                      KURANG BAIK
                    </button>
                  </center>
                </div>
                <div class="col-12">
                  <b-button style="float: right" @click="exportExcelPenilaian" variant="success"><i
                      class="fa fa-file-excel" aria-hidden="true" /> Unduh
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 row">
          <div class="card card-custom gutter-b" style="width: 100%">
            <div class="card-body">
              <h3 class="mb-10">
                Kriteria Penilaian PM PTSP {{ tahunDashboard }}
              </h3>
              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Kelembagaan</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.series[0]" :style="{
                        'background-color':
                          this.optionsPemdatotal_1.colors + '!important',
                      }">
                        <strong>{{ this.series[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Sumber Daya Manusia</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.sdm[0]" :style="{
                        'background-color':
                          this.optionsPemdatotal_2.colors + '!important',
                      }">
                        <strong>{{ this.sdm[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Sarana dan Prasarana</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.sarpras[0]" :style="{
                        'background-color':
                          this.optionsPemdatotal_3.colors + '!important',
                      }">
                        <strong>{{ this.sarpras[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span v-if="tahunDashboard == '2021'">Nilai Realisasi</span>
                    <span v-else>Nilai Implementasi OSS</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.realisasi[0]" :style="{
                        'background-color':
                          this.optionsPemdatotal_4.colors + '!important',
                      }">
                        <strong>{{ this.realisasi[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-3">
                  <div class="col-sm-4">
                    <span v-if="tahunDashboard == '2021'">Nilai Inovasi</span>
                    <span v-else>Nilai Keluaran</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.inovasi[0]" :style="{
                        'background-color':
                          this.optionsPemdatotal_5.colors + '!important',
                      }">
                        <strong>{{ this.inovasi[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <!-- <b-progress :max="max" height="2rem">
                                <b-progress-bar :value="value">
                                    Progress: <strong>{{ value.toFixed(2) }} / {{ max }}</strong>
                                </b-progress-bar>
                            </b-progress> -->
            </div>
          </div>
          <!-- <div class="col-lg-4" style="widh:300px">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Kelembagaan</h6>
                                <div id="chart">
                                    <apexchart height="180" :options="optionsPemdatotal_1" :series="series"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="col card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Sumber Daya Manusia</h6>
                                <div id="chart">
                                    <apexchart height="180" :options="optionsPemdatotal_2" :series="sdm"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Sarana dan Prasarana Kerja</h6>
                                <div id="chart">
                                    <apexchart height="180" :options="optionsPemdatotal_3" :series="sarpras"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Implementasi OSS</h6>
                                <div id="chart">
                                    <apexchart height="180" :options="optionsPemdatotal_4" :series="realisasi"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Keluaran</h6>
                                <div id="chart">
                                    <apexchart height="180" :options="optionsPemdatotal_5" :series="inovasi"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
      <div class="row" v-if="pmppb.length != 0">
        <div class="col-lg-5">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <center>
                <h2>NILAI TOTAL</h2>
              </center>
              <center>
                <h2>PENILAIAN MANDIRI PPB {{ tahunDashboard }}</h2>
              </center>
              <div class="row">
                <div class="col-6" id="chart">
                  <apexchart height="250" :options="optionsTotalPPB" :series="totalppb"></apexchart>
                </div>
                <div class="col-6">
                  <center v-if="totalppbchart >= 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        this.optionsTotalPPB.colors[0] + ' !important',
                      color: this.optionsTotalPPB.colors[0] + ' !important',
                    }">
                      SANGAT BAIK
                    </button>
                  </center>
                  <center v-else-if="totalppbchart >= 60 && totalppbchart < 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        this.optionsTotalPPB.colors[0] + ' !important',
                      color: this.optionsTotalPPB.colors[0] + ' !important',
                    }">
                      BAIK
                    </button>
                  </center>
                  <center v-else style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        this.optionsTotalPPB.colors[0] + ' !important',
                      color: this.optionsTotalPPB.colors[0] + ' !important',
                    }">
                      KURANG BAIK
                    </button>
                  </center>
                </div>
                <div class="col-12">
                  <b-button style="float: right" @click="exportExcelPenilaianPPB" variant="success"><i
                      class="fa fa-file-excel" aria-hidden="true" /> Unduh
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 row">
          <div class="card card-custom gutter-b" style="width: 100%">
            <div class="card-body">
              <h3 class="mb-10">
                Kriteria Penilaian PM PPB {{ tahunDashboard }}
              </h3>
              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Penerapan Perizinan Berusaha Berbasis Risiko</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.penyusunan[0]" :style="{
                        'background-color':
                          this.optionsPemdaPpbtotal_1.colors + '!important',
                      }">
                        <strong>{{ this.penyusunan[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Penyederhanaan Persyaratan Dasar Perizinan
                      Berusaha</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.reformasi[0]" :style="{
                        'background-color':
                          this.optionsPemdaPpbtotal_2.colors + '!important',
                      }">
                        <strong>{{ this.reformasi[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Peningkatan iklim investasi</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.koneksi[0]" :style="{
                        'background-color':
                          this.optionsPemdaPpbtotal_3.colors + '!important',
                      }">
                        <strong>{{ this.koneksi[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <!-- <b-progress :max="max" height="2rem">
                                <b-progress-bar :value="value">
                                    Progress: <strong>{{ value.toFixed(2) }} / {{ max }}</strong>
                                </b-progress-bar>
                            </b-progress> -->
            </div>
          </div>
          <!-- <div class="col-lg-4" style="widh:300px">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Penerapan Perizinan Berusaha Berbasis Risiko</h6>
                                <div id="chart">
                                    <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_1"
                                        :series="penyusunan"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Penyederhanaan Persyaratan Dasar Perizinan Berusaha</h6>
                                <div id="chart">
                                    <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_2"
                                        :series="reformasi"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card card-custom gutter-b">
                            <div class="card-body">
                                <h6>Nilai Peningkatan iklim investasi</h6>
                                <div id="chart">
                                    <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_3"
                                        :series="koneksi"></apexchart>
                                </div>
                            </div>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>

    <div v-else-if="
      user.id_role === 'ppb_prov' ||
      user.id_role === 'ppb_kab' ||
      user.id_role === 'ppb_kot' ||
      user.id_role === 'ppb_pemda' ||
      user.id_role === 'ppb_kabupaten' ||
      user.id_role === 'ppb_kota'
    ">
      <div v-if="surveyor == null"></div>
      <div v-else>
        <div class="row" v-if="
          Date.now() >= new Date(surveyor.tanggal_mulai) &&
          Date.now() <= new Date(surveyor.tanggal_selesai)
        ">
          <div class="col-12">
            <b-alert show variant="secondary">Verifikasi dan validasi lapangan akan dilakukan pada tanggal
              {{ format_date(surveyor.tanggal_mulai) }} sampai dengan
              {{ format_date(surveyor.tanggal_selesai) }} oleh surveyor dari PT
              Sucofindo dengan nama {{ surveyor.name_user }}
            </b-alert>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <div class="card card-custom gutter-b" style="background-color: #cfd8dc">
            <div class="card-body">
              <center>
                <h6>NILAI TOTAL</h6>
              </center>
              <center>
                <h6>PENILAIAN MANDIRI</h6>
              </center>
              <div id="chart">
                <apexchart type="radialBar" height="250" :options="optionsTotalPPB" :series="totalppb">
                </apexchart>
              </div>
              <center v-if="totalppbchart >= 80">
                <h6>SANGAT BAIK</h6>
              </center>
              <center v-else-if="totalppbchart >= 60 && totalppbchart < 80">
                <h6>BAIK</h6>
              </center>
              <center v-else>
                <h6>KURANG BAIK</h6>
              </center>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <h6>Nilai Penyusunan Peraturan Daerah</h6>
              <div id="chart">
                <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_1" :series="penyusunan">
                </apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <h6>Nilai Reformasi Pelaksanaan Perizinan Berusaha</h6>
              <div id="chart">
                <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_2" :series="reformasi">
                </apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <h6>Nilai Koneksi Pemda Dengan Sistem OSS</h6>
              <div id="chart">
                <apexchart type="radialBar" height="200" :options="optionsPemdaPpbtotal_3" :series="koneksi">
                </apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="user.id_role === 'ppb_kl'">
      <div v-if="surveyor == null"></div>
      <div v-else>
        <div class="row mr-1" v-if="
          Date.now() >= new Date(surveyor.tanggal_mulai) &&
          Date.now() <= new Date(surveyor.tanggal_selesai)
        ">
          <div class="col-12">
            <b-alert show variant="primary">Verifikasi dan validasi lapangan akan dilakukan pada tanggal
              <strong>{{ format_date(surveyor.tanggal_mulai) }}</strong> sampai
              dengan
              <strong>{{ format_date(surveyor.tanggal_selesai) }}</strong> oleh
              surveyor dari PT Surveyor Indonesia dengan nama
              <strong>{{ surveyor.name_user }}</strong>
            </b-alert>
          </div>
        </div>
      </div>
      <b-row class="mr-1">
        <b-col md="8"></b-col>
        <b-col md="2"> </b-col>
        <b-col md="2">
          <b-form inline class="mb-5" style="float: right">
            <label class="mr-sm-2" for="inline-form-custom-select-pref">Tahun
            </label>
            <b-form-select id="inline-form-custom-select-pref" class="mb-sm-0" :options="[
              { text: 'Pilih tahun', value: null },
              '2021',
              '2022',
              '2023',
              '2024',
            ]" :value="null" v-model="tahunDashboard" @change="this.loadChartDashboard"></b-form-select>
          </b-form>
        </b-col>
      </b-row>
      <div class="row mr-1" v-if="tahunDashboard == '2023' || tahunDashboard == '2024'">
        <div class="col-lg-5">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body text-center mt-5">
              <h3>
                Nilai {{ nilaiPeserta.nama_kl }} Tahun {{ tahunDashboard }}
              </h3>
              <div v-if="nilaiPeserta.total_akhir >= 80">
                <h1 style="color: green" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button v-if="nilaiPeserta.total_akhir >= 80" class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': 'green' + ' !important',
                    color: 'green' + ' !important',
                  }">
                    SANGAT BAIK
                  </button>
                </div>
              </div>

              <div v-else-if="
                nilaiPeserta.total_akhir >= 60 &&
                nilaiPeserta.total_akhir < 80
              ">
                <h1 style="color: #0f98b4" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': '#0F98B4' + ' !important',
                    color: '#0F98B4' + ' !important',
                  }">
                    BAIK
                  </button>
                </div>
              </div>

              <div v-else>
                <h1 style="color: orange" class="text-bold">
                  {{ nilaiPeserta.total_akhir }}
                </h1>
                <div class="mt-2">
                  <button class="btnIndikator" :style="{
                    width: '40% !important',
                    'border-color': 'orange' + ' !important',
                    color: 'orange' + ' !important',
                  }">
                    KURANG BAIK
                  </button>
                </div>
              </div>

              <div class="mt-7">
                <span style="font-size: 12px; color: red">
                  * Semua nilai sesuai dengan hasil tahapan Verifikasi Lapangan
                  dan Quality Control
                </span>
              </div>
              <b-button v-if="nilaiPeserta.total_akhir >= 60 &&
                nilaiPeserta.total_akhir <= 100"class="mt-5" @click="downloadSertifikat()" variant="success"> <i class="fa fa-file-pdf"
                aria-hidden="true" /> Unduh Sertifikat</b-button>
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="card card-custom gutter-b bg-primary" style="height: 90%">
            <div class="card-body text-center text-white">
              <h4 class="mt-5">
                Daftar Hasil Akhir Pemaparan Nomine dan Uji Petik
              </h4>
              <div class="mt-10">
                <b-button data-toggle="modal" data-target="#modal-daftarNomine" @click="initDatatable()"
                  variant="success" style="width: 100px; font-size: 14px"><i class="fa fa-eye"></i> Lihat</b-button>
              </div>
              <div><b-button v-if="checkUrutan()" class="mt-10" @click="downloadSertifikatPeringkat()" variant="secondary"> <i class="fa fa-file-pdf"
                aria-hidden="true" /> Unduh Sertifikat</b-button></div>

              <!-- <h1 v-if="user.id_role == 'ptsp_provinsi'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/38
              </h1>
              <h3 v-if="user.id_role == 'ptsp_kabupaten'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/415
              </h3>
              <h3 v-if="user.id_role == 'ptsp_kota'" style="font-size:40px" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/93
              </h3>
              <h3 v-if="user.id_role == 'ppb_kl'">
                {{ nilaiPeserta.peringkat_final }}/18
              </h3>
              <img
              class="mt-2"
                width="80"
                height="80"
                src="https://img.icons8.com/external-fauzidea-flat-fauzidea/64/external-trophy-back-to-school-fauzidea-flat-fauzidea.png"
                alt="external-trophy-back-to-school-fauzidea-flat-fauzidea"
              /> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-3">
          <div class="card card-custom gutter-b bg-primary" style="height: 90%;">
            <div class="card-body text-center text-white">
              <h3>Peringkat</h3>

              <h1 v-if="user.id_role == 'ptsp_provinsi'">
                {{ nilaiPeserta.peringkat_final }}/38
              </h1>
              <h3 v-if="user.id_role == 'ptsp_kabupaten'">
                {{ nilaiPeserta.peringkat_final }}/415
              </h3>
              <h3 v-if="user.id_role == 'ptsp_kota'">
                {{ nilaiPeserta.peringkat_final }}/93
              </h3>
              <h1 style="font-size:40px" v-if="user.id_role == 'ppb_kl'" class="mt-5">
                {{ nilaiPeserta.peringkat_final }}/18
              </h1>
              <img
              class="mt-2"
                width="80"
                height="80"
                src="https://img.icons8.com/external-fauzidea-flat-fauzidea/64/external-trophy-back-to-school-fauzidea-flat-fauzidea.png"
                alt="external-trophy-back-to-school-fauzidea-flat-fauzidea"
              />
            </div>
          </div>
        </div> -->
        <div class="col-lg-4">
          <div class="card card-custom gutter-b bg-success text-white" style="cursor: pointer" data-toggle="modal"
            data-target="#modal-compareNilai">
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span> Nilai {{ user.name }} Berdasarkan Tahun Penilaian </span>
              </div>
              <div class="col-md-2" style="">
                <i style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  " class="fa fa-arrow-up text-success"></i>
              </div>
            </div>
          </div>
          <div class="card card-custom gutter-b bg-success text-white" style="cursor: pointer" data-toggle="modal"
            data-target="#modal-daftarPeserta" @click="initDatatablePeserta()">
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span> Daftar Nilai Seluruh Kementerian/Lembaga </span>
              </div>
              <div class="col-md-2">
                <i style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  " class="fa fa-arrow-up text-success"></i>
              </div>
            </div>
          </div>
          <!-- <div
            class="card card-custom gutter-b bg-success text-white"
            style="cursor: pointer"
            data-toggle="modal"
            data-target="#modal-daftarNomine"
            @click="initDatatable()"
          >
            <div class="row card-text m-3">
              <div class="col-md-10">
                <span> Daftar Nomine Kementerian/Lembaga </span>
              </div>
              <div class="col-md-2">
                <i
                  style="
                    display: inline-block;
                    border-radius: 60px;
                    box-shadow: 0 0 2px #888;
                    padding: 0.5em 0.6em;
                    background-color: white;
                  "
                  class="fa fa-arrow-up text-success"
                ></i>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="row" v-if="pm.length != 0">
        <div class="col-lg-5">
          <div class="card card-custom gutter-b">
            <div class="card-body">
              <center>
                <h2>NILAI TOTAL</h2>
              </center>
              <center>
                <h2>PENILAIAN MANDIRI PPB {{ tahunDashboard }}</h2>
              </center>
              <div class="row">
                <div class="col-6" id="chart">
                  <apexchart height="250" :options="optionsTotalPPB" :series="totalppbkl"></apexchart>
                </div>
                <div class="col-6">
                  <center v-if="totalppbklchart >= 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        optionsTotalPPB.colors[0] + ' !important',
                      color: optionsTotalPPB.colors[0] + ' !important',
                    }">
                      SANGAT BAIK
                    </button>
                  </center>
                  <center v-else-if="totalppbklchart >= 60 && totalppbklchart < 80" style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        optionsTotalPPB.colors[0] + ' !important',
                      color: optionsTotalPPB.colors[0] + ' !important',
                    }">
                      BAIK
                    </button>
                  </center>
                  <center v-else style="
                      width: 60%;
                      margin: 0;
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      -ms-transform: translate(-50%, -50%);
                      transform: translate(-50%, -50%);
                    ">
                    <button class="btnIndikator" :style="{
                      'border-color':
                        optionsTotalPPB.colors[0] + ' !important',
                      color: optionsTotalPPB.colors[0] + ' !important',
                    }">
                      KURANG BAIK
                    </button>
                  </center>
                </div>
                <div class="col-12">
                  <b-button style="float: right" @click="exportExcelPenilaianKL" variant="success"><i
                      class="fa fa-file-excel" aria-hidden="true" /> Unduh
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 row">
          <div class="card card-custom gutter-b" style="width: 100%">
            <div class="card-body">
              <h3 class="mb-10">
                Kriteria Penilaian PM PPB {{ tahunDashboard }}
              </h3>
              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Penerapan Perizinan Berusaha</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.penyusunankl[0]" :style="{
                        'background-color':
                          this.optionsKltotal_1.colors + '!important',
                      }">
                        <strong>{{ this.penyusunankl[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Penyederhanaan Perizinan Berusaha Sektor</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.reformasikl[0]" :style="{
                        'background-color':
                          this.optionsKltotal_2.colors + '!important',
                      }">
                        <strong>{{ this.reformasikl[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <div>
                <div class="row mb-8">
                  <div class="col-sm-4">
                    <span>Nilai Peningkatan iklim investasi</span>
                  </div>
                  <div class="col-sm-8">
                    <b-progress max="100" variant="#fff" height="20px">
                      <b-progress-bar :value="this.koneksikl[0]" :style="{
                        'background-color':
                          this.optionsKltotal_3.colors + '!important',
                      }">
                        <strong>{{ this.koneksikl[0] }}</strong>
                      </b-progress-bar>
                    </b-progress>
                  </div>
                </div>
              </div>

              <!-- <b-progress :max="max" height="2rem">
                                <b-progress-bar :value="value">
                                    Progress: <strong>{{ value.toFixed(2) }} / {{ max }}</strong>
                                </b-progress-bar>
                            </b-progress> -->
            </div>
          </div>
        </div>
        <!-- <div class="col-xl-3">
                    <div class="card card-custom gutter-b" style="background-color:#cfd8dc">
                        <div class="card-body">
                            <center>
                                <h6>NILAI TOTAL</h6>
                            </center>
                            <center>
                                <h6>PENILAIAN MANDIRI</h6>
                            </center>
                            <div id="chart">
                                <apexchart type="radialBar" height="250" :options="optionsTotalPPB" :series="totalppbkl">
                                </apexchart>
                            </div>
                            <center v-if="totalppbklchart >= 80">
                                <h6>SANGAT BAIK</h6>
                            </center>
                            <center v-else-if="totalppbklchart >= 60 && totalppbklchart < 80">
                                <h6>BAIK</h6>
                            </center>
                            <center v-else>
                                <h6>KURANG BAIK</h6>
                            </center>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="card card-custom gutter-b">
                        <div class="card-body">
                            <h6>Nilai Penerapan Perizinan Berusaha</h6>
                            <div id="chart">
                                <apexchart type="radialBar" height="200" :options="optionsKltotal_1" :series="penyusunankl">
                                </apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="card card-custom gutter-b">
                        <div class="card-body">
                            <h6>Nilai Penyederhanaan Perizinan Berusaha Sektor</h6>
                            <div id="chart">
                                <apexchart type="radialBar" height="200" :options="optionsKltotal_2" :series="reformasikl">
                                </apexchart>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="card card-custom gutter-b">
                        <div class="card-body">
                            <h6>Nilai Peningkatan Iklim Investasi</h6>
                            <div id="chart">
                                <apexchart type="radialBar" height="200" :options="optionsKltotal_3" :series="koneksikl">
                                </apexchart>
                            </div>
                        </div>
                    </div>
                </div> -->
      </div>
    </div>

    <div v-else-if="user.id_role === 'asosiasi'">
      <div class="row" v-if="pm.length != 0">
        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="background-color: #cfd8dc">
            <div class="card-body">
              <center>
                <h2>Nilai Total</h2>
              </center>
              <div id="chart">
                <apexchart type="radialBar" height="540" :options="optionsTotalHipmi" :series="totalhipmi">
                </apexchart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-8 row">
          <div class="col-lg-4" style="width: 300px">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Tangible/Tampilan</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_a" :series="tangiable">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Empathy/Empati</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_b" :series="emphaty"></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Reliability/Keterandalan</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_c" :series="reliability">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Responsiveness/Daya Tanggap</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_d" :series="dayatanggap">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Assurance/Jaminan Kepastian</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_e" :series="kepastian">
                  </apexchart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="card card-custom gutter-b">
              <div class="card-body">
                <center>
                  <h6>Nilai Total Kinerja</h6>
                </center>
                <div id="chart">
                  <apexchart type="radialBar" height="200" :options="optionshipmitotal_f" :series="kinerja"></apexchart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else-if="
      user.id_role === 'super_user' ||
      user.id_role === 'dpb' ||
      user.id_role === 'expert' ||
      user.id_role === 'qc' ||
      user.id_role === 'pendamping_qc'
    ">
      <div class="row">
        <div class="col-lg-6">
          <b-button @click="exportexcel" variant="success"><i class="fa fa-file-excel" aria-hidden="true" /> Excel
          </b-button>
        </div>
        <div class="col-lg-6">
          <b-form inline class="mb-5" style="float: right">
            <label class="mr-sm-2 font-size-h6" for="inline-form-custom-select-pref" style="">Tahun
            </label>
            <b-form-select id="inline-form-custom-select-pref" class="mb-sm-0 font-size-h6" :options="[
              { text: 'Pilih tahun', value: null },
              '2021',
              '2022',
              '2023',
              '2024',
            ]" :value="null" v-model="tahunDashboard" @change="this.loadChartDashboardAdmin"></b-form-select>
          </b-form>
        </div>
      </div>
      <br />
      <div class="row">
        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body" style="position: relative">
              <h4>Total Peserta</h4>
              <div class="row m-0">
                <div class="col bg-light-success px-5 py-3 rounded-xl mr-7 mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    Provinsi
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[0] }}
                  </span>
                </div>
                <div class="col bg-light-danger px-5 py-3 rounded-xl mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    Kabupaten
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[1] }}
                  </span>
                </div>
              </div>
              <div class="row m-0">
                <div class="col bg-light-info px-5 py-3 rounded-xl mr-7 mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    Kota
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[2] }}
                  </span>
                </div>
                <div class="col bg-light-warning px-5 py-3 rounded-xl mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    Kementerian/Lembaga
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[3] }}
                  </span>
                </div>
              </div>
              <div class="row m-0">
                <div class="col bg-light-success px-5 py-3 rounded-xl mr-7 mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    PK HIPMI
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[4] }}
                  </span>
                </div>
                <div class="col bg-light-danger px-5 py-3 rounded-xl mb-7">
                  <span class="text-dark font-weight-bold font-size-h6 d-block my-2">
                    PK Provinsi
                  </span>
                  <span class="text-dark font-weight-bold font-size-h6">
                    {{ series27[0].data[5] }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body">
              <center>
                <h4>Jumlah PM PTSP</h4>
              </center>
              <!-- Rounded tabs -->
              <ul id="myTab" role="tablist"
                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav"
                style="text-align: center">
                <li class="nav-item flex-sm-fill">
                  <a id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true"
                    class="nav-link border-0 text-uppercase font-weight-bold text-center active">Provinsi</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kabupaten</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kota</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="home" role="tabpanel" aria-labelledby="home-tab" class="tab-pane fade px-4 py-5 show active">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions20" :series="series20">
                    </apexchart>
                  </div>
                </div>
                <div id="profile" role="tabpanel" aria-labelledby="profile-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <apexchart type="donut" height="250" :options="chartOptions21" :series="series21">
                    </apexchart>
                  </div>
                </div>
                <div id="contact" role="tabpanel" aria-labelledby="contact-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions22" :series="series22">
                    </apexchart>
                  </div>
                </div>
              </div>
              <!-- End rounded tabs -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body">
              <center>
                <h4>Jumlah PM PPB</h4>
              </center>
              <!-- Rounded tabs -->
              <ul id="myTab" role="tablist"
                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                <li class="nav-item flex-sm-fill">
                  <a id="ppbprov-tab" data-toggle="tab" href="#ppbprov" role="tab" aria-controls="ppbprov"
                    aria-selected="true" class="nav-link border-0 text-uppercase font-weight-bold active">Provinsi</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="ppbkab-tab" data-toggle="tab" href="#ppbkab" role="tab" aria-controls="ppbkab"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kabupaten</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="ppbkot-tab" data-toggle="tab" href="#ppbkot" role="tab" aria-controls="ppbkot"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kota</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="kl-tab" data-toggle="tab" href="#kl" role="tab" aria-controls="kl" aria-selected="false"
                    class="nav-link border-0 text-uppercase font-weight-bold">Kementerian/Lembaga</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="ppbprov" role="tabpanel" aria-labelledby="ppbprov-tab"
                  class="tab-pane fade px-4 py-5 show active">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions23" :series="series23">
                    </apexchart>
                  </div>
                </div>
                <div id="ppbkab" role="tabpanel" aria-labelledby="ppbkab-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions24" :series="series24">
                    </apexchart>
                  </div>
                </div>
                <div id="ppbkot" role="tabpanel" aria-labelledby="ppbkot-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions25" :series="series25">
                    </apexchart>
                  </div>
                </div>
                <div id="kl" role="tabpanel" aria-labelledby="kl-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions26" :series="series26">
                    </apexchart>
                  </div>
                </div>
              </div>
              <!-- End rounded tabs -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body">
              <center>
                <h4>Jumlah Pemangku Kepentingan</h4>
              </center>
              <!-- Rounded tabs -->
              <ul id="myTab" role="tablist"
                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                <li class="nav-item flex-sm-fill">
                  <a id="pkprov-tab" data-toggle="tab" href="#pkprov" role="tab" aria-controls="pkprov"
                    aria-selected="true" class="nav-link border-0 text-uppercase font-weight-bold active">Provinsi</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="pkhipmi-tab" data-toggle="tab" href="#pkhipmi" role="tab" aria-controls="pkhipmi"
                    aria-selected="false"
                    class="nav-link border-0 text-uppercase font-weight-bold text-center">HIPMI</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="pkprov" role="tabpanel" aria-labelledby="pkprov-tab"
                  class="tab-pane fade px-4 py-5 show active">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="230" :options="chartOptions29" :series="series29">
                    </apexchart>
                  </div>
                </div>
                <div id="pkhipmi" role="tabpanel" aria-labelledby="pkhipmi-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions28" :series="series28">
                    </apexchart>
                  </div>
                </div>
              </div>
              <!-- End rounded tabs -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body">
              <center>
                <h4>Jumlah Verifikasi PM PTSP</h4>
              </center>
              <!-- Rounded tabs -->
              <ul id="myTab" role="tablist"
                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                <li class="nav-item flex-sm-fill">
                  <a id="verpmprov-tab" data-toggle="tab" href="#verpmprov" role="tab" aria-controls="verpmprov"
                    aria-selected="true" class="nav-link border-0 text-uppercase font-weight-bold active">Provinsi</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="verpmkab-tab" data-toggle="tab" href="#verpmkab" role="tab" aria-controls="verpmkab"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kabupaten</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="verpmkot-tab" data-toggle="tab" href="#verpmkot" role="tab" aria-controls="verpmkot"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kota</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="verpmprov" role="tabpanel" aria-labelledby="verpmprov-tab"
                  class="tab-pane fade px-4 py-5 show active">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions30" :series="series30">
                    </apexchart>
                  </div>
                </div>
                <div id="verpmkab" role="tabpanel" aria-labelledby="verpmkab-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions31" :series="series31">
                    </apexchart>
                  </div>
                </div>
                <div id="verpmkot" role="tabpanel" aria-labelledby="verpmkot-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions32" :series="series32">
                    </apexchart>
                  </div>
                </div>
              </div>
              <!-- End rounded tabs -->
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="card card-custom gutter-b" style="height: 90%">
            <div class="card-body">
              <center>
                <h4>Jumlah Verifikasi PM PPB</h4>
              </center>
              <!-- Rounded tabs -->
              <ul id="myTab" role="tablist"
                class="nav nav-tabs nav-pills flex-column flex-sm-row text-center bg-light border-0 rounded-nav">
                <li class="nav-item flex-sm-fill">
                  <a id="verppbprov-tab" data-toggle="tab" href="#verppbprov" role="tab" aria-controls="verppbprov"
                    aria-selected="true" class="nav-link border-0 text-uppercase font-weight-bold active">Provinsi</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="verppbkab-tab" data-toggle="tab" href="#verppbkab" role="tab" aria-controls="verppbkab"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kabupaten</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="verppbkot-tab" data-toggle="tab" href="#verppbkot" role="tab" aria-controls="verppbkot"
                    aria-selected="false" class="nav-link border-0 text-uppercase font-weight-bold">Kota</a>
                </li>
                <li class="nav-item flex-sm-fill">
                  <a id="verkl-tab" data-toggle="tab" href="#verkl" role="tab" aria-controls="kl" aria-selected="false"
                    class="nav-link border-0 text-uppercase font-weight-bold">Kementerian/Lembaga</a>
                </li>
              </ul>
              <div id="myTabContent" class="tab-content">
                <div id="verppbprov" role="tabpanel" aria-labelledby="verppbprov-tab"
                  class="tab-pane fade px-4 py-5 show active">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions33" :series="series33">
                    </apexchart>
                  </div>
                </div>
                <div id="verppbkab" role="tabpanel" aria-labelledby="verppbkab-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions34" :series="series34">
                    </apexchart>
                  </div>
                </div>
                <div id="verppbkot" role="tabpanel" aria-labelledby="verppbkot-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions35" :series="series35">
                    </apexchart>
                  </div>
                </div>
                <div id="verkl" role="tabpanel" aria-labelledby="verkl-tab" class="tab-pane fade px-4 py-5">
                  <div id="chart">
                    <!-- <apexchart  height="440" :options="optionsTotal" :series="total"></apexchart> -->
                    <apexchart type="donut" height="250" :options="chartOptions36" :series="series36">
                    </apexchart>
                  </div>
                </div>
              </div>
              <!-- End rounded tabs -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="
      user.id_role != 'expert' &&
      user.id_role != 'super_user' &&
      user.id_role != 'dpb' &&
      user.id_role != 'peserta' &&
      user.id_role != 'admin_bimtek' &&
      user.id_role != 'eo'
    " class="row">
      <div class="col-lg-8">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Kegiatan</h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-0">
            <!--begin::Item-->
            <div class="mb-6" v-for="kegiatan in kegiatan" :key="kegiatan.id">
              <!--begin::Content-->
              <div class="d-flex align-items-center flex-grow-1">
                <!--begin::Checkbox-->
                <!-- <label class="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4">
                                <input type="checkbox" value="1" />
                                <span></span>
                            </label> -->
                <!--end::Checkbox-->
                <!--begin::Section-->
                <div class="d-flex flex-wrap align-items-center justify-content-between w-100">
                  <!--begin::Info-->
                  <div class="d-flex flex-column align-items-cente py-2 w-75">
                    <!--begin::Title-->
                    <span class="text-muted font-weight-bold">{{ kegiatan.tanggal_mulai | dateToString }} s.d.
                      {{ kegiatan.tanggal_selesai | dateToString }}
                    </span>
                    <!--end::Title-->
                    <!--begin::Data-->
                    <a href="#" class="text-dark-75 font-weight-bold text-hover-primary font-size-lg mb-1">{{
                      kegiatan.agenda }}</a>
                    <!--end::Data-->
                  </div>
                  <!--end::Info-->
                  <!--begin::Label-->
                  <div v-if="kegiatan.status_sistem === 'Buka'">
                    <span class="label label-lg label-light-success label-inline font-weight-bold py-4">{{
                      kegiatan.status_sistem }}</span>
                  </div>
                  <div v-else-if="kegiatan.status_sistem === 'Tutup Sementara'">
                    <span class="label label-lg label-light-warning label-inline font-weight-bold py-4">{{
                      kegiatan.status_sistem }}</span>
                  </div>
                  <div v-else>
                    <span class="label label-lg label-light-danger label-inline font-weight-bold py-4">{{
                      kegiatan.status_sistem }}</span>
                  </div>
                  <!--end::Label-->
                </div>
                <!--end::Section-->
              </div>
              <!--end::Content-->
            </div>
            <!--end::Item-->
          </div>
          <!--end: Card Body-->
        </div>
      </div>
      <div class="col-lg-4" v-if="user.id_role === 'ptsp_provinsi'">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Hasil Penilaian Lainnya</h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th style="font-size: 14px"></th>
                    <th style="font-size: 14px">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <b>Penilaian Organisasi Pengusaha HIPMI</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Tangible/Tampilan
                      </div>
                    </td>
                    <td>{{ hipmi.total_a }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Empathy/Empati
                      </div>
                    </td>
                    <td>{{ hipmi.total_b }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Reliability/Keterandalan
                      </div>
                    </td>
                    <td>{{ hipmi.total_c }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Responsiveness/Daya Tanggap
                      </div>
                    </td>
                    <td>{{ hipmi.total_d }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Assurance/Jaminan Kepastian
                      </div>
                    </td>
                    <td>{{ hipmi.total_e }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Total Kinerja
                      </div>
                    </td>
                    <td>{{ hipmi.total_f }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!--end: Card Body-->
        </div>
      </div>

      <div class="col-lg-4" v-else-if="
        user.id_role === 'ptsp_kab' ||
        user.id_role === 'ptsp_kot' ||
        user.id_role === 'ptsp_kabkot' ||
        user.id_role === 'ptsp_kabupaten' ||
        user.id_role === 'ptsp_kota'
      ">
        <div class="card card-custom card-stretch gutter-b">
          <!--begin::Header-->
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Hasil Penilaian Lainnya</h3>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body">
            <!--begin::Item-->
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th style="font-size: 14px"></th>
                    <th style="font-size: 14px">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="2">
                      <b>Penilaian Pemangku Kepentingan Provinsi</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kinerja Pelayanan Perizinan Berusaha
                      </div>
                    </td>
                    <td>{{ ppk.total_1 }}</td>
                    <!-- <td></td> -->
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">Kinerja PPB</div>
                    </td>
                    <td>{{ ppk.total_2 }}</td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <b>Penilaian Organisasi Pengusaha HIPMI</b>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Tangible/Tampilan
                      </div>
                    </td>
                    <td>{{ hipmi.total_a }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Empathy/Empati
                      </div>
                    </td>
                    <td>{{ hipmi.total_b }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Reliability/Keterandalan
                      </div>
                    </td>
                    <td>{{ hipmi.total_c }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Responsiveness/Daya Tanggap
                      </div>
                    </td>
                    <td>{{ hipmi.total_d }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Assurance/Jaminan Kepastian
                      </div>
                    </td>
                    <td>{{ hipmi.total_e }}</td>
                  </tr>
                  <tr>
                    <td>
                      <div style="margin-left: 10px">
                        Kriteria Total Kinerja
                      </div>
                    </td>
                    <td>{{ hipmi.total_f }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--end::Item-->
          </div>
          <!--end: Card Body-->
        </div>
      </div>
    </div>

    <div v-if="user.id_role === 'peserta'" class="row">
      <div class="col-lg-5 col-12">
        <div class="card card-custom gutter-b">
          <div class="card-body row" style="position: relative">
            <div class="">
              <div>
                <a class="font-weight-bolder font-size-h3 text-dark-75 text-hover-primary">{{ user.name }}</a>
                <div class="text-muted mb-1">{{ user.username }}</div>
                <br />
                <div class="mb-1">{{ bagianText }}</div>
                <br />
                <div class="mb-1">
                  <i class="flaticon2-placeholder mr-2 font-size-lg text-hover-primary" style="color: #0f98b4"></i>
                  {{ daerah }}
                </div>
                <div class="pb-6">
                  <div class="mb-2">
                    <span class="font-weight-bold mr-2"><i class="flaticon2-new-email" style="color: green"></i>
                    </span>
                    {{ user.email }}
                  </div>
                  <div class="d-flex align-items-center justify-content-between mb-2"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-12">
        <div class="card card-custom gutter-b" style="height: 90%">
          <div class="card-body row">
            <div class="col-12">
              <a class="font-weight-bolder font-size-h3 text-dark-75">Sertifikat Kegiatan</a>
              <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
                <table id="example" class="table table-striped table-bordered" style="width: 100%">
                  <thead>
                    <tr>
                      <th style="width: 5%">No</th>
                      <!-- <th>Status</th> -->
                      <th>Nama Kegiatan</th>
                      <th>Nomor Sertifikat</th>
                      <th>Aksi</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- diubah sesuai kebutuhan tabel -->
                    <tr v-for="(row, index) in sert" :key="row.id">
                      <td class="text-center">{{ index + 1 }}</td>
                      <!-- <td class="text-center">
                        <i
                          v-if="
                            row.status_verifikasi == 'Menunggu Verifikasi' ||
                            row.status_verifikasi == null
                          "
                          class="flaticon2-hourglass-1 mr-2"
                          style="color: gold; margin-bottom: 15px"
                          v-b-tooltip.hover
                          title="Menunggu Verifikasi"
                        ></i>
                        <i
                          v-else
                          class="flaticon2-check-mark mr-5"
                          style="color: greenyellow; margin-bottom: 15px"
                          v-b-tooltip.hover
                          title="Sudah Terverifikasi"
                        ></i>
                      </td> -->
                      <td>{{ row.nama_kegiatan }}</td>
                      <td>
                        {{ row.nomor_sertifikat }}
                      </td>
                      <td>
                        <b-button v-if="row.file_sertifikat === null" disabled size="sm" variant="gray" class="m-1 pr-2"
                          v-b-tooltip.hover title="Sertifikat Belum Tersedia">
                          <i class="fa fa-ban"></i>
                        </b-button>
                        <b-button v-else @click="exportSertifikat(row.file_sertifikat)" size="sm" variant="success"
                          class="m-1 pr-2"><i class="flaticon-download-1"></i></b-button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="user.id_role === 'admin_bimtek' || user.id_role === 'eo'" class="row">
      <div class="col-lg-6 mb-5"></div>
      <div class="col-lg-6 mb-5">
        <b-form class="d-flex align-items-center">
          <label class="mr-sm-2 font-size-h6 c mb-0" style="">Kegiatan</label>
          <s-select :options="listNamaKegiatan" :reduce="(data) => data.value" v-model="namaKegiatan"
            @input="this.loadDashboardAdminBimtek" label="text" placeholder="Semua Kegiatan"
            class="flex-grow-1 ml-2"></s-select>
        </b-form>
      </div>
      <div class="col-lg-3 col-6">
        <div class="card card-custom gutter-b" style="height: 90%; background-color: #ffd98e">
          <div class="card-body" style="position: relative">
            <h1>
              <strong style="font-size: 34px">{{ TotalPendaftar }}</strong>
              <i class="fa fa-users float-right" aria-hidden="true" style="color: orange"></i>
            </h1>
            <p style="font-size: 16px">Pendaftar</p>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-6">
        <div class="card card-custom gutter-b" style="height: 90%; background-color: #7bd18f">
          <div class="card-body" style="position: relative">
            <h1>
              <strong style="font-size: 34px">{{ TotalPeserta }}</strong>
              <i class="fa fa-users float-right" aria-hidden="true" style="color: green"></i>
            </h1>
            <p style="font-size: 16px">Peserta</p>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-6">
        <div class="card card-custom gutter-b" style="height: 90%; background-color: #8ae0f2">
          <div class="card-body" style="position: relative">
            <h1>
              <strong style="font-size: 34px">{{ TotalPreTest }}</strong>
              <i class="fa fa-list-ol float-right" aria-hidden="true" style="color: #0f98b4"></i>
            </h1>
            <p style="font-size: 15px">Selesai PreTest</p>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-6">
        <div class="card card-custom gutter-b" style="height: 90%; background-color: #7bd18f">
          <div class="card-body" style="position: relative">
            <h1>
              <strong style="font-size: 34px">{{ TotalPostTest }}</strong>
              <i class="fa fa-list-ol float-right" aria-hidden="true" style="color: green"></i>
            </h1>
            <p style="font-size: 15px">Selesai PostTest</p>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-6">
        <div class="card card-custom gutter-b" style="height: 90%; background-color: #8ae0f2">
          <div class="card-body" style="position: relative">
            <h1>
              <strong style="font-size: 34px">{{ totalSurvey }}</strong>
              <i class="fa fa-check-square float-right" aria-hidden="true" style="color: #0f98b4"></i>
            </h1>
            <p style="font-size: 16px">Isi Survey</p>
          </div>
        </div>
      </div>
      <div class="col-lg-8 col-12">
        <div class="card card-custom gutter-b">
          <div class="card-body" style="position: relative">
            <a v-if="
              this.namaKegiatan == 'Semua Kegiatan' ||
              this.namaKegiatan == null
            " class="font-weight-bolder font-size-h2 text-dark-75">
              Peserta Bimtek Terbaru
              <i class="fa fa-user float-right" aria-hidden="true" style="color: #ffa800"></i></a>
            <a v-else class="font-weight-bolder font-size-h2 text-dark-75">
              Pendaftar Bimtek Terbaru
              <i class="fa fa-user float-right" aria-hidden="true" style="color: #ffa800"></i></a>

            <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
              <table id="#" class="table table-striped table-bordered" style="width: 100%">
                <thead>
                  <tr>
                    <!-- <th style="width: 5%">No</th> -->
                    <th>Nama Peserta</th>
                    <th>NIP</th>
                    <th>DPMPTSP</th>
                    <!-- <th>Bagian</th> -->
                  </tr>
                </thead>
                <tbody>
                  <!-- diubah sesuai kebutuhan tabel -->
                  <tr v-for="row in ListPendaftar.slice(0, 5)" :key="row.id">
                    <!-- <td class="text-center"></td> -->
                    <td>{{ row.name || row.nama_user }}</td>
                    <td>{{ row.username || row.nip }}</td>
                    <td>
                      {{ row.id_ptsp == null ? "" : checkPTSP(row.id_ptsp) }}
                    </td>
                    <!-- <td>
                      {{ row.bagian }}
                      {{ row.bagian == null ? "" : checkBagian(row.bagian) }}
                    </td> -->
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right">
              <a class="text-primary cursor-pointer" @click="navigateRouteBimtek">
                Lihat lebih detail
                <span><i class="fa fa-chevron-right" style="color: #3699ff"></i></span>
              </a>
            </div>
            <!-- <div
              v-for="(data, i) in ListPendaftar.slice(0, 5)"
              :key="i"
              class="row"
            >
              <div class="col-6">
                {{ data.name }}
              </div>
              <div class="col-6">
                <a class="float-right">{{ data.username }}</a>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="card card-custom gutter-b">
          <div class="card-body" style="position: relative">
            <a class="font-weight-bolder font-size-h2 text-dark-75">
              Kegiatan Terdekat
              <i class="fa fa-thumb-tack float-right" aria-hidden="true" style="color: red"></i></a>
            <br />
            <div style="overflow-x: auto; overflow-y: hidden" class="pt-2">
              <table id="#" class="table table-striped table-bordered" style="width: 100%">
                <thead>
                  <tr>
                    <!-- <th style="width: 5%">No</th> -->
                    <th>Kegiatan</th>
                    <th>Tanggal</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- diubah sesuai kebutuhan tabel -->
                  <tr v-for="row in jadwalKegiatan.slice(0, 5)" :key="row.id">
                    <!-- <td class="text-center"></td> -->
                    <td>{{ row.nama_kegiatan }}</td>
                    <td>{{ row.tanggal_mulai }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="float-right">
              <a href="#" @click="$router.push('/kegiatanbmtk')">
                Lihat lebih detail
                <span><i class="fa fa-chevron-right" style="color: #3699ff"></i></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-daftarNomine" tabindex="-1" role="dialog"
      aria-labelledby="modal-daftarNomineLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="user.id_role == 'ptsp_provinsi'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nomine Provinsi
            </h5>
            <h5 v-else-if="user.id_role == 'ptsp_kabupaten'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nomine Kabupaten
            </h5>
            <h5 v-else-if="user.id_role == 'ptsp_kota'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nomine Kota
            </h5>
            <h5 v-else-if="user.id_role == 'ppb_kl'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nomine Kementerian/Lembaga
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="overflow-x: auto">
              <table id="example" class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%">
                <thead>
                  <tr>
                    <th>No</th>
                    <th v-if="user.id_role == 'ptsp_provinsi'">
                      Nama Provinsi
                    </th>
                    <th v-else-if="user.id_role == 'ptsp_kabupaten'">
                      Nama Kabupaten
                    </th>
                    <th v-else-if="user.id_role == 'ptsp_kota'">
                      Nama Kabupaten
                    </th>
                    <th v-else-if="user.id_role == 'ppb_kl'">
                      Nama Kementerian/Lembaga
                    </th>
                    <th class="text-center">Total Akhir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataNomine" :key="i">
                    <td style="width: 10%"></td>
                    <td style="width: 45%" v-if="user.id_role == 'ppb_kl'">
                      {{ item.nama_kl }}
                    </td>
                    <td style="width: 45%" v-else>
                      {{ item.daerah }}
                    </td>
                    <td style="width: 45%" class="text-center">
                      {{ item.total_akhir }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-daftarPeserta" tabindex="-1" role="dialog"
      aria-labelledby="modal-daftarNomineLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 v-if="user.id_role == 'ptsp_provinsi'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nilai Seluruh Provinsi
            </h5>
            <h5 v-else-if="user.id_role == 'ptsp_kabupaten'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nilai Seluruh Kabupaten
            </h5>
            <h5 v-else-if="user.id_role == 'ptsp_kota'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nilai Seluruh Kota
            </h5>
            <h5 v-else-if="user.id_role == 'ppb_kl'" class="modal-title" id="modal-daftarNomineLabel">
              Daftar Nilai Seluruh Kementerian/Lembaga
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="overflow-x: auto">
              <table id="tblPeserta" class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%" v-if="user.id_role == 'ptsp_provinsi'">
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2" v-if="user.id_role == 'ptsp_provinsi'">
                      Nama Provinsi
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ptsp_kabupaten'">
                      Nama Kabupaten
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ptsp_kota'">
                      Nama Kabupaten
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ppb_kl'">
                      Nama Kementerian/Lembaga
                    </th>
                    <th colspan="2" class="text-center">Nilai PTSP</th>
                    <th rowspan="2">Total Nilai PTSP</th>
                    <th rowspan="2">Total Nilai PPB</th>
                    <th rowspan="2">Nilai Akhir</th>
                  </tr>
                  <tr>
                    <th>Penilaian Mandiri</th>
                    <th>Pelaku Usaha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataPeserta" :key="i">
                    <td></td>
                    <td>
                      {{ item.daerah }}
                    </td>
                    <td>{{ item.total_pm }}</td>
                    <td>{{ item.total_asosiasi }}</td>
                    <td>{{ item.total_ptsp }}</td>
                    <td>{{ item.total_ppb }}</td>
                    <td>{{ item.total_akhir }}</td>
                  </tr>
                </tbody>
              </table>
              <table id="tblPeserta" class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%" v-else-if="
                  user.id_role == 'ptsp_kabupaten' ||
                  user.id_role == 'ptsp_kota'
                ">
                <thead>
                  <tr>
                    <th rowspan="2">No</th>
                    <th rowspan="2" v-if="user.id_role == 'ptsp_provinsi'">
                      Nama Provinsi
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ptsp_kabupaten'">
                      Nama Kabupaten
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ptsp_kota'">
                      Nama Kabupaten
                    </th>
                    <th rowspan="2" v-else-if="user.id_role == 'ppb_kl'">
                      Nama Kementerian/Lembaga
                    </th>
                    <th colspan="3" class="text-center">Nilai PTSP</th>
                    <th rowspan="2">Total Nilai PTSP</th>
                    <th rowspan="2">Total Nilai PPB</th>
                    <th rowspan="2">Nilai Akhir</th>
                  </tr>
                  <tr>
                    <th>Penilaian Mandiri</th>
                    <th>Pelaku Usaha</th>
                    <th>Pemangku Kepentingan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataPeserta" :key="i">
                    <td></td>
                    <td>
                      {{ item.daerah }}
                    </td>
                    <td>{{ item.total_pm }}</td>
                    <td>{{ item.total_asosiasi }}</td>
                    <td>{{ item.total_pk }}</td>
                    <td>{{ item.total_ptsp }}</td>
                    <td>{{ item.total_ppb }}</td>
                    <td>{{ item.total_akhir }}</td>
                  </tr>
                </tbody>
              </table>
              <table id="tblPeserta" class="table table-striped table-bordered"
                style="border-collapse: collapse; width: 100%" v-else-if="user.id_role == 'ppb_kl'">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Kementerian/Lembaga</th>
                    <th>Total PPB</th>
                    <th>Nilai Akhir</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in dataPeserta" :key="i">
                    <td></td>
                    <td>
                      {{ item.nama_kl }}
                    </td>
                    <td>{{ item.total_ppb }}</td>
                    <td>{{ item.total_akhir }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modal-compareNilai" tabindex="-1" role="dialog"
      aria-labelledby="modal-daftarNomineLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-daftarNomineLabel">
              Hasil Penilaian Mandiri {{ user.name }} Berdasarkan Tahun
              Penilaian
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div style="justify-content: center">
              <b-form-group v-if="user.id_role != 'ppb_kl'" class="justify-content-center">
                <b-form-radio-group style="justify-content: center" id="btn-radios-2" v-model="tipeSelected"
                  :options="options" @change="loadNilaiCompare(tipeSelected)" button-variant="outline-primary" size="lg"
                  name="radio-btn-outline" buttons>
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div id="chart">
              <apexchart type="bar" height="400" :options="chartOptionsCompare" :series="seriesNilaiCompare">
              </apexchart>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { LOGOUT } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import VueSelect from "vue-select";

export default {
  name: "dashboard",
  data() {
    return {
      TotalPeserta: 0,
      TotalPendaftar: 0,
      ListPendaftar: 0,
      TotalPreTest: 0,
      TotalPostTest: 0,
      totalSurvey: 0,
      jadwalKegiatan: [],
      sert: [],
      daerah: null,
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      status_verifikasi_nilai: null,
      kegiatan: [],
      tahun: 2024,
      profil: [],
      pm: [],
      pmppb: [],
      vf: [],
      vfppb: [],
      surveyor: [],
      ppk: [],
      hipmi: [],
      user: JSON.parse(localStorage.getItem("user")),
      //chart nilai compare tahun
      tipeSelected: "ptsp",
      seriesNilaiCompare: [],
      chartOptionsCompare: [],
      options: [
        { text: "PTSP", value: "ptsp" },
        { text: "PPB", value: "ppb" },
      ],
      optionskl: [
        { text: "PTSP", value: "ptsp", disabled: true },
        { text: "PPB", value: "ppb" },
      ],
      //data Nomine
      dataNomine: [],
      dataPeserta: [],
      nilaiPeserta: {},
      // chart kelemabagaan
      series: [],
      // colors: ["#e0ebeb"],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
          },
        },
        labels: [""],
      },
      kelembagaanchart: "",
      //

      // chartsdm
      sdm: [],
      sdmchart: "",

      // chartsarpras
      sarpras: [],
      sarpraschart: "",

      // chart realisasi proyek
      realisasi: [],
      realisasichart: "",

      // chart inovasi
      inovasi: [],
      inovasichart: "",

      // total skor
      total: [],
      totalchart: "",

      // chart penyusunan
      penyusunan: [],
      penyusunanchart: "",

      // chart reformasi
      reformasi: [],
      reformasichart: "",

      // chart reformasi
      koneksi: [],
      koneksichart: "",

      // chart totalppb
      totalppb: [],
      totalppbchart: "",

      // chart totalppbkl
      totalppbkl: [],
      totalppbklchart: "",

      // chart penyusunan
      penyusunankl: [],
      penyusunanchartkl: "",

      // chart reformasi
      reformasikl: [],
      reformasiklchart: "",

      // chart reformasi
      koneksikl: [],
      koneksiklchart: "",

      // chart totalhipmi
      totalhipmi: [],
      totalhipmichart: "",

      // chart tangiable
      tangiable: [],
      tangiablechart: "",

      // chart emphaty
      emphaty: [],
      emphatychart: "",

      // chart reliability
      reliability: [],
      reliabilitychart: "",

      // chart dayatanggap
      dayatanggap: [],
      dayatanggapchart: "",

      // chart kepastian
      kepastian: [],
      kepastianchart: "",

      // chart kinerja
      kinerja: [],
      kinerjachart: "",

      // ApexChart Dynamic
      optionsTotal: {},
      optionsTotalPPB: {},
      optionsTotalHipmi: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 155,
              size: "40%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "35px",
                show: true,
                formatter: function (val) {
                  return val.replace(".", ",");
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdatotal_1: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdatotal_2: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdatotal_3: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdatotal_4: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdatotal_5: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdaPpbtotal_1: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdaPpbtotal_2: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsPemdaPpbtotal_3: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsKltotal_1: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsKltotal_2: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },
      optionsKltotal_3: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_a: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_b: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_c: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_d: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_e: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      optionshipmitotal_f: {
        legend: {
          show: false,
        },
        chart: {
          type: "radialBar",
        },
        colors: [],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "55%",
            },

            dataLabels: {
              showOn: "always",
              name: {
                offsetY: -8,
                show: false,
                color: "#888",
                fontSize: "13px",
              },
              value: {
                color: "#111",
                fontSize: "24px",
                show: true,
                formatter: function (val) {
                  return val;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Total Nilai"],
      },

      exportexcell: "",
      chart20: [],
      chart21: [],
      chart22: [],
      chart23: [],
      chart24: [],
      chart25: [],
      chart26: [],

      series20: [],
      chartOptions20: {},

      series21: [],
      chartOptions21: {},

      series22: [],
      chartOptions22: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series23: [],
      chartOptions23: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series24: [],
      chartOptions24: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series25: [],
      chartOptions25: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series26: [],
      chartOptions26: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series27: [],
      chartOptions27: {
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false,
          },
          //height: 350,
          type: "bar",
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        stroke: {
          width: 3,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Provinsi",
            "Kabupaten",
            "Kota",
            "KL",
            "Pemangku Kepentingan HIPMI",
            "Pemangku Kepentingan Provinsi",
          ],
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
        colors: [
          "#1ab7ea",
          "#0084ff",
          "#39539E",
          "#0077B5",
          "#4DC5BD",
          "#F4A803",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     legend: {
      //         show: false
      //     }
      //   }
      // }]
      series28: [],
      chartOptions28: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },
      series29: [],
      chartOptions29: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#F4A803", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series30: [],
      chartOptions30: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series31: [],
      chartOptions31: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series32: [],
      chartOptions32: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series33: [],
      chartOptions33: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series34: [],
      chartOptions34: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series35: [],
      chartOptions35: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },

      series36: [],
      chartOptions36: {
        chart: {
          //   height: 380,
          type: "donut",
        },
        legend: {
          position: "bottom",
        },
        labels: [],
        colors: ["#F04E60", "#4DC5BD"],
        plotOptions: {
          donut: {
            size: "100%",
          },
        },
      },
      tahunDashboard: "2024",

      namaKegiatan: "Semua Kegiatan",
      listNamaKegiatan: [],

      listKegiatan: [],
      dashboardptsp: [],
      bagian: [
        { value: "kadis", text: "Kepala Dinas" },
        {
          value: "kabidpb",
          text: "Kepala Bidang Perizinan Berusaha/Pejabat Fungsional Madya Bidang Perizinan Berusaha",
        },
        {
          value: "kasiepb",
          text: "Kepala Seksi Bidang Perizinan Berusaha/Pejabat Fungsional Muda Bidang Perizinan Berusaha",
        },
        {
          value: "foffpb",
          text: "Staf Front-office Bidang Perizinan Berusaha",
        },
        {
          value: "boffpb",
          text: "Staf Back-office Bidang Perizinan Berusaha",
        },
        {
          value: "hdeskpb",
          text: "Staf Help Desk Bidang Perizinan Berusaha",
        },
        {
          value: "kabiddal",
          text: "Kepala Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Madya Bidang Pengendalian Pelaksanaan",
        },
        {
          value: "kasiedal",
          text: "Kepala Seksi Bidang Pengendalian Pelaksanaan/Pejabat Fungsional Muda Bidang Pengendalian Pelaksanaan",
        },
        { value: "foffdal", text: "Staf Front-office Bidang Dalaks" },
        { value: "boffdal", text: "Staf Back-office Bidang Dalaks" },
        { value: "hdeskdal", text: "Staf Help Desk Bidang Dalaks" },
      ],
      listPTSP: [],
      hasil_nomine: []
    };
  },
  components: {
    VueApexCharts,
    "s-select": VueSelect,
  },
  computed: {
    bagianText() {
      const bagianItem = this.bagian.find(
        (item) => item.value === this.user.bagian
      );
      return bagianItem ? bagianItem.text : this.user.bagian;
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    this.getPtsp();
    this.getPTSPs();
    this.getnotif();
    this.loaddata();
    this.gettanggal();
    this.loadNilaiCompare();
    this.getTotalPeserta();
    this.getTotalPendaftar();
    this.getPreTest();
    this.getPostTest();
    this.getSurvey();
    this.getSertifikat();
    this.getKegiatan();
  },
  methods: {
    navigateRouteBimtek() {
      if (this.namaKegiatan == "Semua Kegiatan" || this.namaKegiatan == null) {
        this.$router.push("/listprofilbmtk/pesertabmtk");
      } else {
        this.$router.push(`/kegiatanbmtk/pendaftar/${this.namaKegiatan}`);
      }
    },
    checkBagian(value) {
      const dataBagian = this.bagian.find((p) => p.value == value);
      // console.log(dataBagian)
      // return dataBagian
      return dataBagian != null ? dataBagian.text : value;
    },
    checkPTSP(id) {
      const dataPTSP = this.listPTSP.find((p) => {
        return p.idptsp == id;
      });
      return dataPTSP.daerah;
    },
    getPTSPs() {
      axios
        .get(this.url + "/profil?sort=idptsp%20ASC", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.listPTSP = response.data.data;
          this.loaddata();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPtsp() {
      axios
        .get(this.url + "/profil?filter=idptsp,=," + this.user.id_ptsp)
        .then((response) => {
          this.daerah = response.data.data[0].daerah;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadNilaiCompare() {
      var jenis = "";
      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi"
      ) {
        jenis = "pemda";
      } else if (this.user.id_role === "ptsp_kabupaten") {
        jenis = "pemda";
      } else if (this.user.id_role === "ptsp_kota") {
        jenis = "pemda";
      } else if (this.user.id_role === "ppb_kl") {
        jenis = "kl";
        this.tipeSelected = "ppb";
      }
      axios
        .get(
          this.url +
            `/report/nilai_berdasarkan_tahun?tipe=${this.tipeSelected}&jenis=${jenis}&id_peserta=` +
            this.user.id_ptsp,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.seriesNilaiCompare = response.data.data.series;
          const categorySentimen = response.data.data.categories;
          //this.optionsChart.xaxis.categories = response.data.data.categories
          this.chartOptionsCompare = {
            ...this.chartOptionsCompare,
            ...{
              colors: ["#01058A", "#1AA8E9"],
              xaxis: {
                categories: categorySentimen,
              },
            },
          };
        });
    },
    loadChartDashboard() {
      var jenis = "";
      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi"
      ) {
        jenis = "Provinsi";
      } else if (this.user.id_role === "ptsp_kabupaten") {
        jenis = "Kabupaten";
      } else if (this.user.id_role === "ptsp_kota") {
        jenis = "Kota";
      } else if (this.user.id_role === "ppb_kl") {
        jenis = "KL";
      }

      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi" ||
        this.user.id_role === "ptsp_kabupaten" ||
        this.user.id_role === "ptsp_kota"
      ) {
        axios
          .get(
            this.url +
              `/report/dashboard_peserta?tahun=` +
              this.tahunDashboard +
              `&tipe=ptsp&jenis=pemda&id_peserta=` +
              this.user.id_ptsp,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            // // kelembagaan chart
            this.series = [];
            this.kelembagaanchart = response.data.data.total_1;
            var hasil_kelembagaan = this.kelembagaanchart.split(".");
            var finalkelembagaan = parseInt(hasil_kelembagaan[0]);
            this.series.push(finalkelembagaan);
            //console.log(this.series)

            let colorSkor1 = response.data.data.total_1_color;
            this.optionsPemdatotal_1.colors = [colorSkor1];

            // // sdmchart
            this.sdm = [];
            this.sdmchart = response.data.data.total_2;
            var hasil_sdm = this.sdmchart.split(".");
            var finalsdm = parseInt(hasil_sdm[0]);
            this.sdm.push(finalsdm);

            let colorSkor2 = response.data.data.total_2_color;
            this.optionsPemdatotal_2.colors = [colorSkor2];

            // // sarpras
            this.sarpras = [];
            this.sarpraschart = response.data.data.total_3;
            var hasil_sarpras = this.sarpraschart.split(".");
            var finalsarpras = parseInt(hasil_sarpras[0]);
            this.sarpras.push(finalsarpras);

            let colorSkor3 = response.data.data.total_3_color;
            this.optionsPemdatotal_3.colors = [colorSkor3];

            // // oss
            this.realisasi = [];
            this.realisasichart = response.data.data.total_4;
            var hasil_realisas = this.realisasichart.split(".");
            var finalrealisas = parseInt(hasil_realisas[0]);
            this.realisasi.push(finalrealisas);

            let colorSkor4 = response.data.data.total_4_color;
            this.optionsPemdatotal_4.colors = [colorSkor4];

            // keluaran chart
            this.inovasi = [];
            this.inovasichart = response.data.data.total_5;
            var hasil_inovasi = this.inovasichart.split(".");
            var finalinovasi = parseInt(hasil_inovasi[0]);
            this.inovasi.push(finalinovasi);

            let colorSkor5 = response.data.data.total_5_color;
            this.optionsPemdatotal_5.colors = [colorSkor5];

            // total chart
            this.total = [];
            this.totalchart = response.data.data.total_skor;
            this.total.push(this.totalchart);

            this.optionsTotal = {
              ...this.optionsTotal,
              ...{
                legend: {
                  show: false,
                },
                chart: {
                  type: "radialBar",
                },
                colors: [response.data.data.total_skor_color],
                plotOptions: {
                  radialBar: {
                    hollow: {
                      margin: 15,
                      size: "60%",
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -8,
                        show: false,
                        color: "#888",
                        fontSize: "13px",
                      },
                      value: {
                        color: "#111",
                        fontSize: "24px",
                        show: true,
                        formatter: function (val) {
                          return val.replace(".", ",");
                        },
                      },
                    },
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: ["Total Nilai"],
              },
            };

            // let colorTotalSkor = ;
            // this.optionsTotal.colors = [response.data.data.total_skor_color]
          });

        axios
          .get(
            this.url +
              `/report/dashboard_peserta?tahun=` +
              this.tahunDashboard +
              `&tipe=ppb&jenis=pemda&id_peserta=` +
              this.user.id_ptsp,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            // penyusunan chart
            this.penyusunan = [];
            this.penyusunanchart = response.data.data.total_1;
            var hasil_penyusunan = this.penyusunanchart.split(".");
            var finalpenyusunan = parseInt(hasil_penyusunan[0]);
            this.penyusunan.push(finalpenyusunan);

            let colorSkor1 = response.data.data.total_1_color;
            this.optionsPemdaPpbtotal_1.colors = [colorSkor1];

            // reformasi chart
            this.reformasi = [];
            this.reformasichart = response.data.data.total_2;
            var hasil_reformasi = this.reformasichart.split(".");
            var finalreformasi = parseInt(hasil_reformasi[0]);
            this.reformasi.push(finalreformasi);

            let colorSkor2 = response.data.data.total_2_color;
            this.optionsPemdaPpbtotal_2.colors = [colorSkor2];

            // koneksi chart
            this.koneksi = [];
            this.koneksichart = response.data.data.total_3;
            var hasil_koneksi = this.koneksichart.split(".");
            var finalkoneksi = parseInt(hasil_koneksi[0]);
            this.koneksi.push(finalkoneksi);

            let colorSkor3 = response.data.data.total_3_color;
            this.optionsPemdaPpbtotal_3.colors = [colorSkor3];

            // totalppb chart
            this.totalppb = [];
            this.totalppbchart = response.data.data.total_skor;
            this.totalppb.push(this.totalppbchart);

            let colorTotalSkor = response.data.data.total_skor_color;
            //this.optionsTotalPPB.colors = [colorTotalSkor]

            this.optionsTotalPPB = {
              ...this.optionsTotalPPB,
              ...{
                legend: {
                  show: false,
                },
                chart: {
                  type: "radialBar",
                },
                colors: [response.data.data.total_skor_color],
                plotOptions: {
                  radialBar: {
                    hollow: {
                      margin: 15,
                      size: "60%",
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -8,
                        show: false,
                        color: "#888",
                        fontSize: "13px",
                      },
                      value: {
                        color: "#111",
                        fontSize: "24px",
                        show: true,
                        formatter: function (val) {
                          return val.replace(".", ",");
                        },
                      },
                    },
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: ["Total Nilai"],
              },
            };
          });

        axios
          .get(
            this.url + `/report/nomine_peserta?jenis=` + jenis + "&tahun="+this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.dataNomine = response.data.data;
            this.dataNomine.sort((a, b) => parseFloat(b.total_akhir) - parseFloat(a.total_akhir));

            // console.log("hasil urutan")
            // console.log(this.dataNomine)
            // this.initDatatable();
            
          });

        axios
          .get(
            this.url + `/report/list_peserta?jenis=` + jenis + "&tahun="+ this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.dataPeserta = response.data.data;
            this.initDatatablePeserta();
          });

        axios
          .get(
            this.url +
              `/report/nilai_peserta?jenis=${jenis}&id=` +
              this.user.id_ptsp +
              "&tahun="+ this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.nilaiPeserta = response.data.data;
          });
      } else if (this.user.id_role === "ppb_kl") {
        axios
          .get(
            this.url +
              `/report/dashboard_peserta?tahun=` +
              this.tahunDashboard +
              `&tipe=ppb&jenis=kl&id_peserta=` +
              this.user.id_ptsp,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            // totalppbkl chart
            this.totalppbkl = [];
            this.totalppbklchart = response.data.data.total_skor;
            this.totalppbkl.push(this.totalppbklchart);

            // let colorTotalSkor = response.data.data.total_skor_color;
            // this.optionsTotalPPB.colors = [colorTotalSkor]

            // peneyusunan chart
            this.penyusunankl = [];
            this.penyusunanchartkl = response.data.data.total_1;
            var hasil_penyusunan = this.penyusunanchartkl.split(".");
            var finalpenyusunan = parseInt(hasil_penyusunan[0]);
            this.penyusunankl.push(finalpenyusunan);

            let colorSkor1 = response.data.data.total_1_color;
            this.optionsKltotal_1.colors = [colorSkor1];

            // reformasi chart
            this.reformasikl = [];
            this.reformasiklchart = response.data.data.total_2;
            var hasil_reformasi = this.reformasiklchart.split(".");
            var finalreformasi = parseInt(hasil_reformasi[0]);
            this.reformasikl.push(finalreformasi);

            let colorSkor2 = response.data.data.total_2_color;
            this.optionsKltotal_2.colors = [colorSkor2];

            // koneksi chart
            this.koneksikl = [];
            this.koneksiklchart = response.data.data.total_3;
            var hasil_koneksi = this.koneksiklchart.split(".");
            var finalkoneksi = parseInt(hasil_koneksi[0]);
            this.koneksikl.push(finalkoneksi);

            let colorSkor3 = response.data.data.total_3_color;
            this.optionsKltotal_3.colors = [colorSkor3];

            this.optionsTotalPPB = {
              ...this.optionsTotalPPB,
              ...{
                legend: {
                  show: false,
                },
                chart: {
                  type: "radialBar",
                },
                colors: [response.data.data.total_skor_color],
                plotOptions: {
                  radialBar: {
                    hollow: {
                      margin: 15,
                      size: "60%",
                    },

                    dataLabels: {
                      showOn: "always",
                      name: {
                        offsetY: -8,
                        show: false,
                        color: "#888",
                        fontSize: "13px",
                      },
                      value: {
                        color: "#111",
                        fontSize: "24px",
                        show: true,
                        formatter: function (val) {
                          return val.replace(".", ",");
                        },
                      },
                    },
                  },
                },
                stroke: {
                  lineCap: "round",
                },
                labels: ["Total Nilai"],
              },
            };
          });

        axios
          .get(
            this.url + `/report/nomine_peserta?jenis=` + jenis + "&tahun="+this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.dataNomine = response.data.data;
            this.dataNomine.sort((a, b) => parseFloat(b.total_akhir) - parseFloat(a.total_akhir));
          });

        axios
          .get(
            this.url + `/report/list_peserta?jenis=` + jenis + "&tahun="+this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.dataPeserta = response.data.data;
          });

        axios
          .get(
            this.url +
              `/report/nilai_peserta?jenis=${jenis}&id=` +
              this.user.id_ptsp +
              "&tahun="+this.tahunDashboard,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.nilaiPeserta = response.data.data;
          });
      }
    },
    loadChartDashboardAdmin() {
      axios
        .get(this.url + "/activity_chart?tahun=" + this.tahunDashboard, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.chart20 = [];
          this.chart21 = [];
          this.chart22 = [];
          this.chart23 = [];
          this.chart24 = [];
          this.chart25 = [];
          this.chart26 = [];
          this.chart8 = [];
          this.chart9 = [];
          this.chart10 = [];
          this.chart11 = [];
          this.chart12 = [];
          this.chart13 = [];
          this.chart14 = [];
          this.chart15 = [];
          this.chart16 = [];
          this.chart17 = [];
          this.series20 = [];
          this.series21 = [];
          this.series22 = [];
          this.series23 = [];
          this.series23 = [];
          this.series24 = [];
          this.series25 = [];
          this.series26 = [];
          this.series27 = [];
          this.series28 = [];
          this.series29 = [];
          this.series30 = [];
          this.series31 = [];
          this.series32 = [];
          this.series33 = [];
          this.series34 = [];
          this.series35 = [];
          this.series36 = [];

          this.chart20 = response.data.jumlah_pm_prov;
          this.chart21 = response.data.jumlah_pm_kab;
          this.chart22 = response.data.jumlah_pm_kot;
          this.chart23 = response.data.jumlah_ppb_prov;
          this.chart24 = response.data.jumlah_ppb_kab;
          this.chart25 = response.data.jumlah_ppb_kot;
          this.chart26 = response.data.jumlah_ppb_kl;
          this.chart8 = response.data.total;
          this.chart9 = response.data.jumlah_asosiasi;
          this.chart10 = response.data.jumlah_pk;
          this.chart11 = response.data.jumlah_pm_prov_ver;
          this.chart12 = response.data.jumlah_pm_kab_ver;
          this.chart13 = response.data.jumlah_pm_kot_ver;
          this.chart14 = response.data.jumlah_ppb_prov_ver;
          this.chart15 = response.data.jumlah_ppb_kab_ver;
          this.chart16 = response.data.jumlah_ppb_kot_ver;
          this.chart17 = response.data.jumlah_ppb_kl_ver;

          this.chartOptions20.labels = [];
          this.chartOptions21.labels = [];
          this.chartOptions22.labels = [];
          this.chartOptions23.labels = [];
          this.chartOptions24.labels = [];
          this.chartOptions25.labels = [];
          this.chartOptions26.labels = [];
          this.chartOptions27.labels = [];
          this.chartOptions28.labels = [];
          this.chartOptions29.labels = [];
          this.chartOptions30.labels = [];
          this.chartOptions31.labels = [];
          this.chartOptions32.labels = [];
          this.chartOptions33.labels = [];
          this.chartOptions34.labels = [];
          this.chartOptions35.labels = [];
          this.chartOptions36.labels = [];

          // this.idpenilaian=response.data.data.id;
          for (var prop in this.chart20) {
            this.series20.push(this.chart20[prop]);
          }
          var label20 = [];
          for (var prop in this.chart20) {
            var ini = prop.replace("_", " ");
            label20.push(ini + " = " + this.chart20[prop]);
          }

          this.chartOptions20 = {
            ...this.chartOptions20,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label20,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart21) {
            this.series21.push(this.chart21[prop]);
          }
          var label21 = [];
          for (var prop in this.chart21) {
            var ini = prop.replace("_", " ");
            label21.push(ini + " = " + this.chart21[prop]);
          }
          this.chartOptions21 = {
            ...this.chartOptions21,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label21,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart22) {
            this.series22.push(this.chart22[prop]);
          }
          var label22 = [];
          for (var prop in this.chart22) {
            var ini = prop.replace("_", " ");
            label22.push(ini + " = " + this.chart22[prop]);
          }

          this.chartOptions22 = {
            ...this.chartOptions22,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label22,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart23) {
            this.series23.push(this.chart23[prop]);
          }
          var label23 = [];
          for (var prop in this.chart23) {
            var ini = prop.replace("_", " ");
            label23.push(ini + " = " + this.chart23[prop]);
          }
          this.chartOptions23 = {
            ...this.chartOptions23,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label23,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart24) {
            this.series24.push(this.chart24[prop]);
          }
          var label24 = [];
          for (var prop in this.chart24) {
            var ini = prop.replace("_", " ");
            label24.push(ini + " = " + this.chart24[prop]);
          }

          this.chartOptions24 = {
            ...this.chartOptions23,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label24,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart25) {
            this.series25.push(this.chart25[prop]);
          }
          var label25 = [];
          for (var prop in this.chart25) {
            var ini = prop.replace("_", " ");
            label25.push(ini + " = " + this.chart25[prop]);
          }
          this.chartOptions25 = {
            ...this.chartOptions25,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label25,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart26) {
            this.series26.push(this.chart26[prop]);
          }
          var label26 = [];
          for (var prop in this.chart26) {
            var ini = prop.replace("_", " ");
            label26.push(ini + " = " + this.chart26[prop]);
          }

          this.chartOptions26 = {
            ...this.chartOptions26,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label26,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          var datachart = [];
          for (var prop in this.chart8) {
            datachart.push(this.chart8[prop]);
            // console.log(datachart)
          }
          this.series27.push({ data: datachart });

          for (var prop in this.chart9) {
            this.series28.push(this.chart9[prop]);
          }
          var label28 = [];
          for (var prop in this.chart9) {
            var ini = prop.replace("_", " ");
            label28.push(ini + " = " + this.chart9[prop]);
          }
          this.chartOptions28 = {
            ...this.chartOptions28,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label28,
              colors: ["#F04E60", "#F4A803", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart10) {
            this.series29.push(this.chart10[prop]);
          }
          var label29 = [];
          for (var prop in this.chart10) {
            var ini = prop.replace("_", " ");
            label29.push(ini + " = " + this.chart10[prop]);
          }
          this.chartOptions29 = {
            ...this.chartOptions29,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label29,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart11) {
            this.series30.push(this.chart11[prop]);
          }

          var label30 = [];
          for (var prop in this.chart11) {
            var ini = prop.replace("_", " ");
            label30.push(ini + " = " + this.chart11[prop]);
          }
          this.chartOptions30 = {
            ...this.chartOptions30,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label30,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart12) {
            this.series31.push(this.chart12[prop]);
          }
          var label31 = [];
          for (var prop in this.chart12) {
            var ini = prop.replace("_", " ");
            label31.push(ini + " = " + this.chart12[prop]);
          }

          this.chartOptions31 = {
            ...this.chartOptions31,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label31,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart13) {
            this.series32.push(this.chart13[prop]);
          }
          var label32 = [];
          for (var prop in this.chart13) {
            var ini = prop.replace("_", " ");
            label32.push(ini + " = " + this.chart13[prop]);
          }

          this.chartOptions32 = {
            ...this.chartOptions32,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label32,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart14) {
            this.series33.push(this.chart14[prop]);
          }
          var label33 = [];
          for (var prop in this.chart14) {
            var ini = prop.replace("_", " ");
            label33.push(ini + " = " + this.chart14[prop]);
          }

          this.chartOptions33 = {
            ...this.chartOptions33,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label33,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart15) {
            this.series34.push(this.chart15[prop]);
          }
          var label34 = [];
          for (var prop in this.chart15) {
            var ini = prop.replace("_", " ");
            label34.push(ini + " = " + this.chart15[prop]);
          }

          this.chartOptions34 = {
            ...this.chartOptions34,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label34,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart16) {
            this.series35.push(this.chart16[prop]);
          }
          var label35 = [];
          for (var prop in this.chart16) {
            var ini = prop.replace("_", " ");
            label35.push(ini + " = " + this.chart16[prop]);
          }
          this.chartOptions35 = {
            ...this.chartOptions35,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label35,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };

          for (var prop in this.chart17) {
            this.series36.push(this.chart17[prop]);
          }
          var label36 = [];
          for (var prop in this.chart17) {
            var ini = prop.replace("_", " ");
            label36.push(ini + " = " + this.chart17[prop]);
          }

          this.chartOptions36 = {
            ...this.chartOptions36,
            ...{
              chart: {
                //   height: 380,
                type: "donut",
              },
              legend: {
                position: "bottom",
              },
              labels: label36,
              colors: ["#F04E60", "#4DC5BD"],

              plotOptions: {
                donut: {
                  size: "100%",
                },
              },
            },
          };
        })
        .catch((error) => {
          return error;
          // this.loaddata()
        });
    },
    loadDashboardAdminBimtek() {
      if (
        this.namaKegiatan === null ||
        this.namaKegiatan === "Semua Kegiatan"
      ) {
        this.getTotalPeserta();
        this.getTotalPendaftar();
        this.getPreTest();
        this.getPostTest();
        this.getSurvey();
        // axios
        //   .get(this.url + "/jadwal_peserta", {
        //     headers: {
        //       xth: this.token,
        //     },
        //   })
        //   .then((response) => {
        //     this.TotalPeserta = response.data.data.length;

        //     // Calculate the total number of surveys with non-null file_sertifikat
        //     this.totalSurvey = response.data.data.reduce((count, item) => {
        //       if (item.file_sertifikat !== null) {
        //         return count + 1;
        //       }
        //       return count;
        //     }, 0);
        //     this.ListPendaftar = response.data.data;
        //   })
        //   .catch((error) => {
        //     return error;
        //   });

        // axios
        //   .get(this.url + "/jawaban_peserta", {
        //     headers: {
        //       xth: this.token,
        //     },
        //   })
        //   .then((response) => {
        //     // Initialize counters for Pre Test and Post Test
        //     let preTestCount = 0;
        //     let postTestCount = 0;

        //     // Iterate through the responses
        //     response.data.data.forEach((item) => {
        //       if (
        //         item.jenis_ujian === "Pre Test" &&
        //         item.status_pengerjaan === "Selesai"
        //       ) {
        //         preTestCount++;
        //       } else if (
        //         item.jenis_ujian === "Post Test" &&
        //         item.status_pengerjaan === "Selesai"
        //       ) {
        //         postTestCount++;
        //       }
        //     });

        //     // Assign the counts to the appropriate variables
        //     this.TotalPreTest = preTestCount;
        //     this.TotalPostTest = postTestCount;
        //   });
      } else {
        axios
          .get(
            this.url +
              "/jadwal_peserta?filter=id_kegiatan,=," +
              this.namaKegiatan,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.TotalPendaftar = response.data.data.length;
            this.ListPendaftar = response.data.data;
          })
          .catch((error) => {
            return error;
          });

        axios
          .get(
            this.url +
              "/jadwal_peserta?filter=id_kegiatan,=," +
              this.namaKegiatan +
              ";status_verifikasi,=,Sudah Terverifikasi",
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.TotalPeserta = response.data.data.length;

            // Calculate the total number of surveys with non-null file_sertifikat
            this.totalSurvey = response.data.data.reduce((count, item) => {
              if (item.file_sertifikat !== null) {
                return count + 1;
              }
              return count;
            }, 0);
          })
          .catch((error) => {
            return error;
          });

        axios
          .get(
            this.url +
              "/jawaban_peserta?filter=id_kegiatan,=," +
              this.namaKegiatan,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            // Initialize counters for Pre Test and Post Test
            let preTestCount = 0;
            let postTestCount = 0;

            // Iterate through the responses
            response.data.data.forEach((item) => {
              if (
                item.jenis_ujian === "Pre Test" &&
                item.status_pengerjaan === "Selesai"
              ) {
                preTestCount++;
              } else if (
                item.jenis_ujian === "Post Test" &&
                item.status_pengerjaan === "Selesai"
              ) {
                postTestCount++;
              }
            });

            // Assign the counts to the appropriate variables
            this.TotalPreTest = preTestCount;
            this.TotalPostTest = postTestCount;
          });
      }
    },

    async getnotif() {
      try {
        let datauser = await axios.get(this.url + "/detail", {
          headers: {
            xth: this.token,
          },
        });
        var hasil = datauser.data.data;
        if (hasil.notif_ppb != null) {
          this.$bvToast.toast(hasil.notif_ppb, {
            title: `Peringatan`,
            variant: "warning",
            solid: true,
            autoHideDelay: 30000,
          });
        }
        if (hasil.notif_ptsp != null) {
          this.$bvToast.toast(hasil.notif_ptsp, {
            title: `Peringatan`,
            variant: "warning",
            solid: true,
            autoHideDelay: 30000,
          });
        }
        if (hasil.notif_pk != null) {
          this.$bvToast.toast(hasil.notif_pk, {
            title: `Peringatan`,
            variant: "warning",
            solid: true,
            autoHideDelay: 30000,
          });
        }
      } catch (error) {
        Swal.fire({
          title: error.response.data,
          text: "",
          icon: "error",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store
              .dispatch(LOGOUT)
              .then(() => this.$router.push({ name: "login" }));
          }
        });
        return error;
      }
    },
    initDatatable() {
      setTimeout(() => {
        var t = $("#example").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          scrollX: true,

          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          let i = 1;

          t.cells(null, 0, { search: "applied", order: "applied" }).every(
            function (cell) {
              this.data(i++);
            }
          );
        }).draw();
      }, 300);
    },

    initDatatablePeserta() {
      setTimeout(() => {
        var t = $("#tblPeserta").DataTable({
          pagingType: "full_numbers",
          language: {
            info: "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
            paginate: {
              first: "Awal",
              last: "Akhir",
              next: "Selanjutnya",
              previous: "Sebelumnya",
            },
          },
          scrollX: true,

          responsive: true,
          destroy: true,
          retrieve: true,
          autoFill: true,
          colReorder: true,
        });
        t.on("order.dt search.dt", function () {
          let i = 1;

          t.cells(null, 0, { search: "applied", order: "applied" }).every(
            function (cell) {
              this.data(i++);
            }
          );
        }).draw();
      }, 300);
    },

    loaddata() {
      //pilih kegiatan
      axios
        .get(this.url + "/jadwal_kegiatan", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          const semuaOption = {
            value: "Semua Kegiatan",
            text: "Semua Kegiatan",
          };
          this.listNamaKegiatan = [
            semuaOption,
            ...response.data.data.map((item) => ({
              value: item.id,
              text: item.nama_kegiatan,
            })),
          ];
        });
      // data surveyor
      axios
        .get(
          this.url + "/penempatan?filter=kode_wilayah,=," + this.user.id_ptsp,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.surveyor = response.data.data[0];
        })
        .catch((error) => {
          console.log(error);
          return error;
        });
      // kegiatan
      axios
        .get(this.url + "/tanggal_kegiatan?filter=tahun,=,2024", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.kegiatan = response.data.data;
        })
        .catch((error) => {
          Swal.fire({
            title: error.response.data,
            text: "",
            icon: "error",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$store
                .dispatch(LOGOUT)
                .then(() => this.$router.push({ name: "login" }));
            }
          });
          return error;
        });
      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi" ||
        this.user.id_role === "ptsp_kabupaten" ||
        this.user.id_role === "ptsp_kota"
      ) {
        axios
          .get(this.url + `/profils?filter=idptsp,=,` + this.user.id_ptsp, {
            headers: {
              xth: this.token,
            },
          })
          .then((response) => {
            this.profil = response.data.data;
            if (this.profil.length === 0) {
              // this.inputprofile()
            } else {
              localStorage.setItem("idprofil", response.data.data.id);
            }
            this.status_verifikasi_nilai =
              response.data.data.status_verifikasi_nilai;

            this.getpenilaian();
          })
          .catch((error) => {
            console.log(error);
            return error;
          });

        // survey
        axios
          .get(
            this.url +
              `/survey?filter=idptsp,=,` +
              this.user.id_ptsp +
              `;tahun,=,` +
              this.tahun,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            var survey = response.data.data;
            if (survey.length === 0) {
              const idptsp = this.user.id_ptsp;
              const tahun = this.tahun;
              axios
                .post(
                  this.url + "/survey",
                  {
                    idptsp,
                    tahun,
                  },
                  {
                    headers: {
                      "Content-type": "application/json",
                      xth: this.token,
                    },
                  }
                )
                .then((res) => {
                  localStorage.setItem("idsurvey", response.data.id);
                  return res;
                })
                .catch((err) => {
                  console.log(err);

                  return err;
                });
            } else {
              localStorage.setItem("idsurvey", response.data.data[0].id);
            }
          })
          .catch((error) => {
            console.log(error);
            return error;
          });

        // pm ptsp

        axios
          .get(
            this.url +
              `/pm_ptsp_pemda_2023?filter=idptsp,=,` +
              this.user.id_ptsp +
              ";tahun,=," +
              this.tahun,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.pm = response.data.data;
            console.log("ini pm:");
            console.log(this.pm);

            if (this.pm.length === 0) {
              this.inputpm();
            } else {
              localStorage.setItem(
                "idpm",
                JSON.stringify(response.data.data[0].id)
              );
              this.loadChartDashboard();
            }

            // if (this.user.notif_ppb != null) {
            //     this.$bvToast.toast(this.user.notif_ppb, {
            //         title: `Info`,
            //         variant: 'warning',
            //         solid: true,
            //         autoHideDelay: 30000
            //     })
            // }
            // if (this.user.notif_ptsp != null) {
            //     this.$bvToast.toast(this.user.notif_ptsp, {
            //         title: `Info`,
            //         variant: 'warning',
            //         solid: true,
            //         autoHideDelay: 30000
            //     })
            // }
            // if (this.user.notif_pk != null) {
            //     this.$bvToast.toast(this.user.notif_pk, {
            //         title: `Info`,
            //         variant: 'warning',
            //         solid: true,
            //         autoHideDelay: 30000
            //     })
            // }
          })
          .catch((error) => {
            console.log(error);
            return error;
          });

        // veirifikasi pm ptsp

        // axios.get(this.url + `/verifikasi_ptsp_pemda_2023s?filter=idptsp,=,` + this.user.id_ptsp, {
        //     headers: {
        //         'xth': this.token
        //     }
        // })
        //     .then(response => {
        //         this.vf = response.data.data;

        //         if (this.status_verifikasi_nilai === true) {
        //             // // kelembagaan chart
        //             this.kelembagaanchart = response.data.data.total_1;
        //             var hasil_kelembagaan = this.kelembagaanchart.split(".");
        //             var finalkelembagaan = parseInt(hasil_kelembagaan[0])
        //             this.series.push(finalkelembagaan);

        //             let colorSkor1 = response.data.data.total_1_color;
        //             this.optionsPemdatotal_1.colors = [colorSkor1]

        //             // // sdmchart
        //             this.sdmchart = response.data.data.total_2;
        //             var hasil_sdm = this.sdmchart.split(".");
        //             var finalsdm = parseInt(hasil_sdm[0])
        //             this.sdm.push(finalsdm);

        //             let colorSkor2 = response.data.data.total_2_color;
        //             this.optionsPemdatotal_2.colors = [colorSkor2]

        //             // // sarpras
        //             this.sarpraschart = response.data.data.total_3;
        //             var hasil_sarpras = this.sarpraschart.split(".");
        //             var finalsarpras = parseInt(hasil_sarpras[0])
        //             this.sarpras.push(finalsarpras);

        //             let colorSkor3 = response.data.data.total_3_color;
        //             this.optionsPemdatotal_3.colors = [colorSkor3]

        //             // // oss
        //             this.realisasichart = response.data.data.total_4;
        //             var hasil_realisas = this.realisasichart.split(".");
        //             var finalrealisas = parseInt(hasil_realisas[0])
        //             this.realisasi.push(finalrealisas);

        //             let colorSkor4 = response.data.data.total_4_color;
        //             this.optionsPemdatotal_4.colors = [colorSkor4]

        //             // keluaran chart
        //             this.inovasichart = response.data.data.total_5;
        //             var hasil_inovasi = this.inovasichart.split(".");
        //             var finalinovasi = parseInt(hasil_inovasi[0])
        //             this.inovasi.push(finalinovasi);

        //             let colorSkor5 = response.data.data.total_5_color;
        //             this.optionsPemdatotal_5.colors = [colorSkor5]

        //             // total chart
        //             this.totalchart = response.data.data.total_skor;
        //             this.total.push(this.totalchart);

        //             let colorTotalSkor = response.data.data.total_skor_color;
        //             this.optionsTotal.colors = [colorTotalSkor]
        //         }
        //         // console.log(this.optionsTotal)

        //         // let keys = Object.keys(response.data.data);
        //         // console.log(keys)
        //         // for(let i=0;i<keys.length;i++){
        //         //     console.log([keys[i]+"_color"])
        //         //     if(this.$data['optionsPemda'+keys[i]]){
        //         //         this.$data['optionsPemda'+keys[i]] = {colors:[response.data.data[keys[i]+"_color"]]}
        //         //     }
        //         // }
        //     }).catch(error => {
        //         console.log(error)
        //         return error
        //     });

        // ppb

        axios
          .get(
            this.url +
              `/pm_ppb_pemda_2023?filter=idptsp,=,` +
              this.user.id_ptsp +
              ";tahun,=," +
              this.tahun,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.pmppb = response.data.data;

            if (this.pmppb.length === 0) {
              this.inputppb();
            } else {
              localStorage.setItem(
                "idppb",
                JSON.stringify(response.data.data[0].id)
              );
            }
          })
          .catch((error) => {
            console.log(error);
            return error;
          });

        // verifikasi ppb
        // axios.get(this.url + `/verifikasi_ppb_pemda_2023s?filter=idptsp,=,` + this.user.id_ptsp, {
        //     headers: {
        //         'xth': this.token
        //     }
        // })
        //     .then(response => {
        //         this.vfppb = response.data.data;

        //         if (this.status_verifikasi_nilai === true) {
        //             // penyusunan chart
        //             this.penyusunanchart = response.data.data.total_1;
        //             var hasil_penyusunan = this.penyusunanchart.split(".");
        //             var finalpenyusunan = parseInt(hasil_penyusunan[0])
        //             this.penyusunan.push(finalpenyusunan);

        //             let colorSkor1 = response.data.data.total_1_color;
        //             this.optionsPemdaPpbtotal_1.colors = [colorSkor1]

        //             // reformasi chart
        //             this.reformasichart = response.data.data.total_2;
        //             var hasil_reformasi = this.reformasichart.split(".");
        //             var finalreformasi = parseInt(hasil_reformasi[0])
        //             this.reformasi.push(finalreformasi);

        //             let colorSkor2 = response.data.data.total_2_color;
        //             this.optionsPemdaPpbtotal_2.colors = [colorSkor2]

        //             // koneksi chart
        //             this.koneksichart = response.data.data.total_3;
        //             var hasil_koneksi = this.koneksichart.split(".");
        //             var finalkoneksi = parseInt(hasil_koneksi[0])
        //             this.koneksi.push(finalkoneksi);

        //             let colorSkor3 = response.data.data.total_3_color;
        //             this.optionsPemdaPpbtotal_3.colors = [colorSkor3]

        //             // totalppb chart
        //             this.totalppbchart = response.data.data.total_skor;
        //             this.totalppb.push(this.totalppbchart);

        //             let colorTotalSkor = response.data.data.total_skor_color;
        //             this.optionsTotalPPB.colors = [colorTotalSkor]
        //         }

        //         // let keys = Object.keys(response.data.data);
        //         // for(let i=0;i<keys.length;i++){
        //         //     if(this.$data['optionsPemdaPpb'+keys[i]]){
        //         //         this.$data['optionsPemdaPpb'+keys[i]] = {colors:[response.data.data[keys[i]+"_color"]]}
        //         //     }
        //         // }
        //         // this.idpenilaian=response.data.data.id;

        //     }).catch(error => {
        //         // localStorage.setItem('idpenilaian', 'undefined')
        //         console.log(error)
        //         return error
        //         // this.loaddata()
        //     });

        this.$bvToast.toast(
          `Pastikan Nama Kepala PTSP Pemda di dalam profil telah diisi`,
          {
            title: `Peringatan!`,
            toaster: "b-toaster-top-center",
            solid: true,
            variant: "info",
          }
        );
        // Swal.fire({
        //     title: "",
        //     text: "Pastikan Nama Kepala PTSP Pemda di dalam profil telah diisi",
        //     icon: "info",
        //     confirmButtonClass: "btn btn-secondary"
        // });
      } else if (this.user.id_role === "ppb_kl") {
        axios
          .get(
            this.url + `/profil_ppb_kls?filter=idkl,=,` + this.user.id_ptsp,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.profil = response.data.data;
            if (this.profil.length === 0) {
              // this.inputprofileppbkl()
            } else {
              localStorage.setItem("idprofil", response.data.data.id);
            }
            this.status_verifikasi_nilai =
              response.data.data.status_verifikasi_nilai;
          })
          .catch((error) => {
            console.log(error);
            return error;
            // this.loaddata()
          });

        axios
          .get(
            this.url +
              `/pm_ppb_kl_2023?filter=idkl,=,` +
              this.user.id_ptsp +
              ";tahun,=," +
              this.tahun,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.pm = response.data.data;

            if (this.pm.length === 0) {
              this.inputpm();
            } else {
              localStorage.setItem(
                "idppb",
                JSON.stringify(response.data.data[0].id)
              );
            }

            this.loadChartDashboard();

            // if (this.user.notif_ppb != null) {
            //     this.$bvToast.toast(this.user.notif_ppb, {
            //         title: `Info`,
            //         variant: 'warning',
            //         solid: true
            //     })
            // }
          })
          .catch((error) => {
            console.log(error);
            return error;
          });

        // axios.get(this.url + `/verifikasi_ppb_kl_2023s?filter=idkl,=,` + this.user.id_ptsp, {
        //     headers: {
        //         'xth': this.token
        //     }
        // })
        //     .then(response => {
        //         this.vf = response.data.data;

        //         if (this.status_verifikasi_nilai === true) {
        //             // totalppbkl chart
        //             this.totalppbklchart = response.data.data.total_skor;
        //             this.totalppbkl.push(this.totalppbklchart);

        //             let colorTotalSkor = response.data.data.total_skor_color;
        //             this.optionsTotalPPB.colors = [colorTotalSkor]

        //             // peneyusunan chart
        //             this.penyusunanchartkl = response.data.data.total_1;
        //             var hasil_penyusunan = this.penyusunanchartkl.split(".");
        //             var finalpenyusunan = parseInt(hasil_penyusunan[0])
        //             this.penyusunankl.push(finalpenyusunan);

        //             let colorSkor1 = response.data.data.total_1_color;
        //             this.optionsKltotal_1.colors = [colorSkor1]

        //             // reformasi chart
        //             this.reformasiklchart = response.data.data.total_2;
        //             var hasil_reformasi = this.reformasiklchart.split(".");
        //             var finalreformasi = parseInt(hasil_reformasi[0])
        //             this.reformasikl.push(finalreformasi);

        //             let colorSkor2 = response.data.data.total_2_color;
        //             this.optionsKltotal_2.colors = [colorSkor2]

        //             // koneksi chart
        //             this.koneksiklchart = response.data.data.total_3;
        //             var hasil_koneksi = this.koneksiklchart.split(".");
        //             var finalkoneksi = parseInt(hasil_koneksi[0])
        //             this.koneksikl.push(finalkoneksi);

        //             let colorSkor3 = response.data.data.total_3_color;
        //             this.optionsKltotal_3.colors = [colorSkor3]
        //         }

        //         // let keys = Object.keys(response.data.data);
        //         // for(let i=0;i<keys.length;i++){
        //         //     if(this.$data['optionsKl'+keys[i]]){
        //         //         this.$data['optionsKl'+keys[i]] = {colors:[response.data.data[keys[i]+"_color"]]}
        //         //     }
        //         // }
        //     }).catch(error => {
        //         return error
        //     });

        this.$bvToast.toast(
          `Pastikan Nama Kepala PPB Kementerian/Lembaga di dalam profil telah diisi`,
          {
            title: `Peringatan!`,
            toaster: "b-toaster-top-center",
            solid: true,
            variant: "info",
          }
        );
        // Swal.fire({
        //     title: "",
        //     text: "Pastikan Nama Kepala PPB Kementerian/Lembaga di dalam profil telah diisi",
        //     icon: "info",
        //     confirmButtonClass: "btn btn-secondary"
        // });
      } else if (this.user.id_role === "asosiasi") {
        axios
          .get(
            this.url +
              `/penilaian_asosiasi?filter=idptsp,=,` +
              this.user.id_ptsp +
              `;tahun,=,` +
              this.tahun,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.pm = response.data.data;

            if (this.pm.length === 0) {
              // localStorage.setItem('idpenilaian', 'undefined')
              this.inputpm();
            } else {
              localStorage.setItem(
                "idpenilaian",
                JSON.stringify(response.data.data.id)
              );
            }

            // totalppbkl chart
            this.totalhipmichart = response.data.data.total_skor;
            this.totalhipmi.push(this.totalhipmichart);

            // tangiable chart
            this.tangiablechart = response.data.data.total_a;
            this.tangiable.push(this.tangiablechart);

            let colorSkora = response.data.data.total_a_color;
            this.optionshipmitotal_a.colors = [colorSkora];

            // empathy chart
            this.emphatychart = response.data.data.total_b;
            this.emphaty.push(this.emphatychart);

            let colorSkorb = response.data.data.total_b_color;
            this.optionshipmitotal_b.colors = [colorSkorb];

            // reliability chart
            this.reliabilitychart = response.data.data.total_c;
            this.reliability.push(this.reliabilitychart);

            let colorSkorc = response.data.data.total_c_color;
            this.optionshipmitotal_c.colors = [colorSkorc];

            // dayatanggap chart
            this.dayatanggapchart = response.data.data.total_d;
            this.dayatanggap.push(this.dayatanggapchart);

            let colorSkord = response.data.data.total_d_color;
            this.optionshipmitotal_d.colors = [colorSkord];

            // kepastian chart
            this.kepastianchart = response.data.data.total_e;
            this.kepastian.push(this.kepastianchart);

            let colorSkore = response.data.data.total_e_color;
            this.optionshipmitotal_e.colors = [colorSkore];

            // kinerja chart
            this.kinerjachart = response.data.data.total_f;
            this.kinerja.push(this.kinerjachart);

            let colorSkorf = response.data.data.total_f_color;
            this.optionshipmitotal_f.colors = [colorSkorf];

            let colorTotalSkor = response.data.data.total_skor_color;
            this.optionsTotalHipmi.colors = [colorTotalSkor];

            // let keys = Object.keys(response.data.data);
            // let keys = ['a','b','c','d','e','f']
            // console.log(keys)
            // for(let i=0;i<keys.length;i++){
            //     if(this.$data['optionshipmi'+keys[i]]){
            //         // console.log(this.$data['optionshipmi'+keys[i]])
            //         this.$data['optionshipmi'+keys[i]] = {colors:[response.data.data[keys[i]+"_color"]]}
            //     }
            // }
          })
          .catch((error) => {
            // localStorage.setItem('idpenilaian', 'undefined')
            console.log(error);
            return error;
          });

        axios
          .get(
            this.url + `/profil_asosiasis?filter=idptsp,=,` + this.user.id_ptsp,
            {
              headers: {
                xth: this.token,
              },
            }
          )
          .then((response) => {
            this.profil = response.data.data;
            if (this.profil.length === 0) {
              // this.inputprofileasosiasi()
            } else {
              localStorage.setItem("idprofil", response.data.data.id);
            }
          })
          .catch((error) => {
            console.log(error);
            return error;
          });
      } else if (
        this.user.id_role === "expert" ||
        this.user.id_role === "super_user" ||
        this.user.id_role === "dpb" ||
        this.user.id_role === "qc" ||
        this.user.id_role === "pendamping_qc"
      ) {
        this.loadChartDashboardAdmin();
      } else if (
        this.user.id_role === "admin_bimtek" ||
        this.user.id_role === "eo"
      ) {
        this.loadDashboardAdminBimtek();
      }
    },
    exportexcel() {
      window.open(
        this.url + `/export/activity/` + this.tahunDashboard,
        "_blank"
      );
    },
    exportSertifikat(sert) {
      window.open(sert, "_blank");
    },
    exportExcelPenilaian() {
      window.open(
        this.url +
          `/export/pm_pemda/` +
          this.user.id_ptsp +
          `/` +
          this.tahunDashboard,
        "_blank"
      );
    },
    exportExcelPenilaianPPB() {
      window.open(
        this.url +
          `/export/ppb_pemda/` +
          this.user.id_ptsp +
          `/` +
          this.tahunDashboard,
        "_blank"
      );
    },
    exportExcelPenilaianKL() {
      window.open(
        this.url +
          `/export/ppb_kl/` +
          this.user.id_ptsp +
          `/` +
          this.tahunDashboard,
        "_blank"
      );
    },
    inputpm() {
      if (
        this.user.id_role === "ptsp_provinsi" ||
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_kabupaten" ||
        this.user.id_role === "ptsp_kota"
      ) {
        const idptsp = this.user.id_ptsp;
        const tahun = 2024;
        axios
          .post(
            this.url + "/pm_ptsp_pemda_2023",
            {
              idptsp,
              tahun,
            },
            {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("idpm", JSON.stringify(res.data.id));
            this.$nextTick();
            this.loaddata();

            return res;
          })
          .catch((err) => {
            console.log(err);

            return err;
          });
      } else if (this.user.id_role === "ppb_kl") {
        const idkl = this.user.id_ptsp;
        const tahun = this.tahun;
        axios
          .post(
            this.url + "/pm_ppb_kl_2023",
            {
              idkl,
              tahun,
            },
            {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("idppb", JSON.stringify(res.data.id));
            this.idpm = res.data.id;
            this.loaddata();
            return res;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      } else if (this.user.id_role === "asosiasi") {
        const idptsp = this.user.id_ptsp;
        const persetujuan = "Ya";
        const alasan = "";
        const idasosiasi = this.user.id_ptsp;
        axios
          .post(
            this.url + "/penilaian_asosiasi",
            {
              idptsp,
              persetujuan,
              alasan,
              idasosiasi,
            },
            {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("idpenilaian", JSON.stringify(res.data.id));
            this.loaddata();
            return res;
          })
          .catch((err) => {
            console.log(err);
            return err;
          });
      }
    },
    inputppb() {
      if (
        this.user.id_role === "ptsp_prov" ||
        this.user.id_role === "ptsp_provinsi" ||
        this.user.id_role === "ptsp_kabupaten" ||
        this.user.id_role === "ptsp_kota"
      ) {
        const idptsp = this.user.id_ptsp;
        const tahun = this.tahun;
        axios
          .post(
            this.url + "/pm_ppb_pemda_2023",
            {
              idptsp,
              tahun,
            },
            {
              headers: {
                "Content-type": "application/json",
                xth: this.token,
              },
            }
          )
          .then((res) => {
            localStorage.setItem("idppb", JSON.stringify(res.data.id));
            this.idpm = res.data.id;
            this.loaddata();
            return res;
          })
          .catch((err) => {
            console.log(err);

            return err;
          });
      }
    },
    getpenilaian() {
      // penilaian ptsp prov
      axios
        .get(
          this.url +
            `/penilaian_prov_pemda?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;tahun,=,` +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.ppk = response.data.data;
        })
        .catch((error) => {
          return error;
        });

      axios
        .get(
          this.url +
            `/penilaian_asosiasi?filter=idptsp,=,` +
            this.user.id_ptsp +
            `;tahun,=,` +
            this.tahun,
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.hipmi = response.data.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getTotalPeserta() {
      axios
        .get(this.url + "/jadwal_peserta", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.TotalPeserta = response.data.data.length;
        })
        .catch((error) => {
          return error;
        });
    },
    getTotalPendaftar() {
      axios
        .get(this.url + "/users?filter=name_role,=,Peserta&sort=id;DESC", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.ListPendaftar = response.data.data;
          this.TotalPendaftar = this.ListPendaftar.length;
          this.initDatatable();
          console.log("Total Peserta: ", this.TotalPendaftar);
        })
        .catch((error) => {
          return error;
        });
    },
    getPreTest() {
      axios
        .get(
          this.url +
            "/jawaban_peserta?filter=status_pengerjaan,=,Selesai;jenis_ujian,=,Pre Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.preTest = response.data.data;
          this.TotalPreTest = this.preTest.length;
        });
    },
    getPostTest() {
      axios
        .get(
          this.url +
            "/jawaban_peserta?filter=status_pengerjaan,=,Selesai;jenis_ujian,=,Post Test",
          {
            headers: {
              xth: this.token,
            },
          }
        )
        .then((response) => {
          this.posttest = response.data.data;
          this.TotalPostTest = this.posttest.length;
        });
    },
    getSurvey() {
      axios
        .get(this.url + "/jadwal_peserta", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.survey = response.data.data;
          this.totalSurvey = this.survey.reduce((count, item) => {
            if (item.file_sertifikat !== null) {
              return count + 1;
            }
            return count;
          }, 0);
          console.log("Total Survey (with file_sertifikat):", this.totalSurvey);
        });
    },
    getSertifikat() {
      axios
        .get(this.url + "/jadwal_peserta?filter=id_user,=," + this.user.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          this.sert = response.data.data;
          $("#example").DataTable().destroy();
          initDatatable();
        });
      // $("#example").DataTable().destroy();
      // initDatatable();
    },
    getKegiatan() {
      axios
        .get(this.url + "/jadwal_kegiatan?sort=tanggal_mulai;ASC", {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          const today = new Date().toISOString().split("T")[0]; // Mendapatkan tanggal hari ini dalam format 'YYYY-MM-DD'
          this.jadwalKegiatan = response.data.data.filter(
            (item) => item.tanggal_mulai >= today
          );
        });
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD-MM-YYYY");
      }
    },
    gettanggal() {
      var currentDate = new Date();
      var date =
        currentDate.getFullYear() +
        "-" +
        (currentDate.getMonth() + 1) +
        "-" +
        currentDate.getDate();
      localStorage.setItem("tanggal", date);
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    downloadSertifikat(){
      var url
      if(this.user.id_role === 'ppb_kl'){
        url = this.url+ "/total_nilai_kl/sertifikat?idkl="+ this.user.id_ptsp+"&tahun="+ this.tahunDashboard
      }else{
        url = this.url+ "/total_nilai_pemda/sertifikat?idptsp="+ this.user.id_ptsp+"&tahun="+ this.tahunDashboard
      }
      
      // window.open(url,'_blank')?.focus
      axios({
        url: url, //your url
        method: 'GET',
        headers:{
          xth: this.token,
        },
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'sertifikat-'+this.user.name+'-'+this.tahunDashboard+'.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
    },

    checkUrutan(){
      if(this.user.id_role == 'ppb_kl'){
        var index = this.dataNomine.findIndex(p => p.nama_kl == this.user.name);
      }else{
        var index = this.dataNomine.findIndex(p => p.daerah == this.user.name.replace('PTSP ', ''));
      }
      // console.log('index nya')
      // console.log(index)
      if(index >= 0 && index <= 2){
        return true
      }else{
        return false
      }
    },

    downloadSertifikatPeringkat(){
      var url
      if(this.user.id_role === 'ppb_kl'){
        url = this.url+ "/tabel_nomine_kl/sertifikat?idkl="+ this.user.id_ptsp+"&tahun="+ this.tahunDashboard
      }else{
        url = this.url+ "/tabel_nomine_provinsi/sertifikat?idptsp="+ this.user.id_ptsp+"&tahun="+ this.tahunDashboard
      }
      
      // window.open(url,'_blank')?.focus
      axios({
        url: url, //your url
        method: 'GET',
        headers:{
          xth: this.token,
        },
        responseType: 'blob', // important
    }).then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'sertifikat nomine-'+this.user.name+'-'+this.tahunDashboard+'.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    });
    }
  },
  filters: {
    dateToString(value) {
      let temp = value.split("-");
      let month = parseInt(temp[1]) - 1;
      const monthNames = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      return temp[2] + " " + monthNames[month] + " " + temp[0];
    },
  },
};
</script>

<style scoped>
.btnIndikator {
  border: 2px solid black;
  background-color: white;
  color: black;
  width: 100% !important;
  padding: 5px 10px 5px 10px;
  font-size: 16px;
}

.nav .nav-link {
  display: block;
}

h2 {
  color: #3699ff;
  font-weight: bold;
}

p {
  font-size: 18px;
}

li {
  margin-bottom: 20px;
}

@media (min-width: 576px) {
  .rounded-nav {
    border-radius: 50rem !important;
  }
}

@media (min-width: 576px) {
  .rounded-nav .nav-link {
    border-radius: 50rem !important;
  }
}

/* With arrow tabs */

.with-arrow .nav-link.active {
  position: relative;
}

.with-arrow .nav-link.active::after {
  content: "";
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #2b90d9;
  position: absolute;
  bottom: -6px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
}

/* lined tabs */

.lined .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
  text-align: center;
}

.lined .nav-link:hover {
  border: none;
  border-bottom: 3px solid transparent;
}

.lined .nav-link.active {
  background: none;
  color: #555;
  border-color: #2b90d9;
}

.verticalLine {
  border-left: 1px solid black;
}
</style>